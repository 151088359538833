<div class="operations-container">
  <section class="info-container">
    <h2>Ref. Mandato: <span>MNDT{{ operationInfo?.codeOperation }}</span></h2>

    <div class="date-info-container">
      <h2>Fecha Primer Cobro:</h2>
      <mat-form-field appearance="outline" color="primary" class="input-medium">
        <mat-label>Fecha:</mat-label>
        <input 
          matInput 
          [matDatepicker]="picker" 
          [(ngModel)]="newFirstPaymentDate" 
          [disabled]="isUpdating" 
          placeholder="Selecciona una fecha" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <button 
        class="gold-button" 
        (click)="updateFirstPaymentDate()" 
        [disabled]="isUpdating || !newFirstPaymentDate">
        Enviar
      </button>
    </div>
  </section>

  <section class="details-container">
    <h2>Detalles de la Operación</h2>
    <div class="details-inner-container">
    <div class="detail-box" *ngFor="let detail of details">
      <div class="detail-header">{{ detail.label }}</div>
      <div class="detail-value">{{ formatDetailValue(detail.value) }}</div>
    </div>
  </div>
  </section>

  <section class="tableMaterial" *ngIf="!isLoading && dataSource.data.length > 0">
    <table mat-table [dataSource]="dataSource" matSort>
      
      <ng-container matColumnDef="Cuota">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Cuota</th>
        <td mat-cell class="clickable" *matCellDef="let element">
          <p class="gold">{{ element.cuota === 0 ? 'INI' : element.cuota }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="Fecha de Emisión">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha de Emisión</th>
        <td mat-cell *matCellDef="let element">
          <p>{{ element.fechaEmision ? (element.fechaEmision | date: 'dd/MM/yyyy') : 'Pendiente' }}</p>
        </td>
      </ng-container>
      
      <ng-container matColumnDef="Ref.Pago">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ref. Pago</th>
        <td mat-cell *matCellDef="let element">
          <p>{{ element.refPago }}</p>
        </td>
      </ng-container>
      
      <ng-container matColumnDef="Importe">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Importe cuota</th>
        <td mat-cell *matCellDef="let element">
          <p>{{ element.importe | currency: 'EUR' }}</p>
        </td>
      </ng-container>
      <ng-container matColumnDef="Resto a pagar">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Resto a pagar</th>
        <td mat-cell *matCellDef="let element">
          <p>{{ element.resto | currency: 'EUR' }}</p>
        </td>
      </ng-container>
      <ng-container matColumnDef="Estado">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Estado</th>
        <td mat-cell *matCellDef="let element">
          <p [ngClass]="getStatusClass(element.estado)">{{ stateInstallments(element.estado) }}</p>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr 
        mat-row 
        *matRowDef="let element; columns: displayedColumns;" 
        class="clickable-row"
        (click)="openModal(element)">
      </tr>
    </table>
  </section>
</div>
