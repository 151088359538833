export interface Cards {
    id: string
    maskedPAN: string
    name: string
    holderId: string
}

export interface Card {
    maskedPAN: string
    expiration: string
    name: string
    limits: Limits

}

export interface Limits {
    atm: LimitsParams
    purchase: LimitsParams
    onlinePurchase: LimitsParams
}

export interface LimitsParams {
    daily: string
    weekly: string
    monthly: string
}

export interface User {
    name: string;
    maskedPAN: string;
    type: string;
    account: string;
    id: string;
  }
  
export interface GroupedUser {
    name: string;
    cards: User[];
  }


  export interface CardConfiguration {
    holder: string;
    holderId: string;
    alias: string;
    associatedAccount: string;
    associatedAccountId: string;
  }  
  export interface CardDetail {
    account: AccountBasic,
    alias?: string,
    brand: string,
    expiration?: string,
    holderId: string,
    id: string,
    limits: Limits,
    maskedPAN?: string,
    name: string,
    printedName: string,
    productSituation?: string,
    status: CARD_STATUS,
    type: string;
  }
  export interface Limits {
    atm: PeriodLimits
    purchase: PeriodLimits
    onlinePurchase: PeriodLimits
  }
  
  export interface PeriodLimits {
    daily: string
    weekly: string
    monthly: string
  }
  
  export interface AccountBasic {
    iban: string,
    if: string
  }
  
  export enum CARD_STATUS {
    PENDING_CONTRACT = 'PENDING_CONTRACT',
    PENDING_SHIPMENT = 'PENDING_SHIPMENT',
    PRE_ACTIVATED = 'PRE_ACTIVATED',
    ACTIVATED = 'ACTIVATED',
    ANULLED = 'ANULLED',
    CANCELLED = 'CANCELLED',
    ERROR = 'ERROR'
  }
