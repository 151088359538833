import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
import { catchError, of, tap } from 'rxjs';
import { FormControl } from '@angular/forms';
import { CoreService } from 'src/app/core/services/userArea/core.service';

@Component({
  selector: 'app-remittances-component',
  templateUrl: './remittances-component.component.html',
  styleUrls: ['./remittances-component.component.scss']
})
export class RemittancesComponentComponent implements OnInit {
  public remittancesData!: any[];
  public sortedData!: any[];

  public displayedColumns: string[] = [
    'sefideRemittanceId',
    'createdAt',
    'updatedAt',
    'totalAmount',
    'status',
  ];

  public offset: number = 10;
  public paginationParams: HttpParams = new HttpParams();
  public loader: boolean = true;
  public length!: number;
  public pageSize: number = 10;
  public pageIndex: number = 0;
  public pageSizeOptions: number[] = [10, 20, 30];
  public searchValue!: string;
  public placeholder: string = "Buscar por ID de remesa o cuenta";
  public pageEvent!: PageEvent;

  constructor(private router: Router, private coreService: CoreService) { }

  ngOnInit() {
    this.getRemittancesList();
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.getRemittancesList();
  }

  getRemittancesList(): void {
    this.loader = true;

    this.coreService.getUserDebits()
      .pipe(
        tap((res) => {
          this.sortedData = res.data; 
          this.length = res.total; 
          this.loader = false;
        }),
        catchError((error) => {
          console.error(error);
          this.loader = false;
          return of(null);
        })
      )
      .subscribe();
  }

  sortData(event: { active: string; direction: string }) {
    const data = this.sortedData.slice();
    if (!event.active || event.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = event.direction === 'asc';
      switch (event.active) {
        case 'createdAt': return this.compare(new Date(a.createdAt), new Date(b.createdAt), isAsc);
        case 'totalAmount': return this.compare(a.totalAmount, b.totalAmount, isAsc);
        default: return 0;
      }
    });
  }

  compare(a: number | Date | string, b: number | Date | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  redirectToRemittanceDetail(sefideRemittanceId: string) {
    this.router.navigate([`/contratacion/adeudos/${sefideRemittanceId}`]);
  }

  onSearch(searchControl: FormControl): void {
    this.searchValue = searchControl.value || '';
    this.getRemittancesList();
  }

  translateState(state: string): string {
    switch (state.toLowerCase()) {
      case 'ok': return 'Aprobado';
      case 'pending': return 'Pendiente';
      case 'cancelled': return 'Cancelado';
      default: return state;
    }
  }

  getStatusClass(state: string): string {
    switch (state.toLowerCase()) {
      case 'ok':
        return 'status-aprobado';
      case 'denied':
        return 'status-denegado';
      case 'pending':
        return 'status-pendiente';
      case 'cancelled':
        return 'status-cancelado';
      default:
        return '';
    }
  }
}
