import { HttpParams } from '@angular/common/http';
import { Component, Output, EventEmitter, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent {
  public querySearch = new FormControl();

  @Output() searchEvent = new EventEmitter<FormControl>();
  @Input() placeholder!: string;

  ngOnInit(): void {
    this.querySearch.valueChanges.pipe(debounceTime(300)).subscribe(res => {
      this.searchEvent.emit(this.querySearch)
    })
  }

}
