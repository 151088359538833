<div class="personal-data-content">
  <section class="autoFill_section" *ngIf="_isPartner || isAdministrator">
    <mat-checkbox class="example-margin" [(ngModel)]="autoFill" (ngModelChange)="autoFillRepresentative()">Rellenar con
      los datos del apoderado</mat-checkbox>
  </section>
  <form [formGroup]="personalData" class="personal-data-form">
    <mat-form-field appearance="outline">
      <mat-label>Nombre</mat-label>
      <input matInput formControlName="name" />
      <mat-error *ngIf="!personalData.get('name')?.valid">Introduce un nombre</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Primer Apellido</mat-label>
      <input matInput formControlName="surname1" />
      <mat-error *ngIf="!personalData.get('surname1')?.valid">Introduce un apellido</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Segundo Apellido</mat-label>
      <input matInput formControlName="surname2" />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Sexo</mat-label>
      <mat-select matInput formControlName="gender">
        <mat-option value="F">Femenino</mat-option>
        <mat-option value="M">Masculino</mat-option>
      </mat-select>
      <mat-error *ngIf="!personalData.get('gender')?.valid">Seleccione una opción</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Fecha de nacimiento</mat-label>
      <input matInput [matDatepicker]="picker" formControlName="birthdate" [max]="startDate">
      <mat-hint>DD/MM/YYYY</mat-hint>
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker [startAt]="startDate"></mat-datepicker>
      <mat-error *ngIf="!personalData.get('birthdate')?.valid">Introduzca una fecha correcta DD/MM/YYYY</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Nacionalidad</mat-label>
      <mat-select name="nationality" formControlName="nationality">
        <mat-option *ngFor="let item of country" [value]="item.value">
          {{ item.viewValue }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="!personalData.get('nationality')?.valid">Debe introducir una nacionalidad</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Tipo</mat-label>
      <mat-select matInput formControlName="documentType" (selectionChange)="idDocumentSelect()">
        <mat-option value="DNI">DNI</mat-option>
        <mat-option value="NIE">NIE</mat-option>
      </mat-select>
      <mat-error *ngIf="!personalData.get('documentType')?.valid">Seleccione un tipo</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Número de {{personalData.get('documentType')?.value}}</mat-label>
      <input *ngIf="personalData.get('documentType')?.value === 'DNI' || !personalData.get('documentType')?.value" matInput formControlName="identifier" />
      <input *ngIf="personalData.get('documentType')?.value === 'NIE'" matInput formControlName="nie" />
      <mat-error *ngIf="!personalData.get('{{formControlDNI}}')?.valid && !personalData.get('{{formControlDNI}}')?.hasError('exists')">Introduce un
        {{personalData.get('documentType')?.value}} válido</mat-error>
      <mat-error *ngIf="personalData.get('{{formControlDNI}}')?.hasError('exists')">El número de documento no existe</mat-error>

    </mat-form-field>

    <!--  <mat-form-field appearance="outline" class="short_input">
          <mat-label i18n="@@personal.area.documents">Número de NIE</mat-label>
          <input matInput formControlName="nie" />
          <mat-error
            *ngIf="!personalData.get('nie')?.valid"
            i18n="@@form.document.error"
            >Introduce un DNI/NIE válido</mat-error
          >
        </mat-form-field> -->

    <mat-form-field appearance="outline">
      <mat-label>país de expedición del {{personalData.get('documentType')?.value}}</mat-label>
      <mat-select name="expeditionCountry" formControlName="expeditionCountry">
        <mat-option *ngFor="let item of country" [value]="item.value">
          {{ item.viewValue }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="!personalData.get('expeditionCountry')?.valid">Debe seleccionar un país</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>País de residencia</mat-label>
      <mat-select name="residenceCountry" formControlName="residenceCountry">
        <mat-option *ngFor="let item of country" [value]="item.value">
          {{ item.viewValue }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="!personalData.get('residenceCountry')?.valid">Debe seleccionar un país</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Email</mat-label>
      <input type="email" matInput formControlName="email" />
      <mat-error *ngIf="!personalData.get('email')?.valid && !personalData.get('email')?.hasError('exists')">Debe introducir un email correcto</mat-error>
      <mat-error *ngIf="personalData.get('email')?.hasError('exists')">El email no coincide con este usuario</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Prefijo</mat-label>
      <mat-select name="prefix" formControlName="prefix">
        <mat-option *ngFor="let item of prefix" [value]="item.prefix">
          {{ item.prefix }} ({{ item.country }})
        </mat-option>
      </mat-select>
      <mat-error *ngIf="!personalData.get('prefix')?.valid">Debe introducir un prefijo</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Teléfono</mat-label>
      <input matInput formControlName="phone" />
      <mat-error *ngIf="!personalData.get('phone')?.valid && !personalData.get('phone')?.hasError('exists')">Debe introducir un teléfono correcto</mat-error>
      <mat-error *ngIf="personalData.get('phone')?.hasError('exists')">El teléfono no coincide con este
        usuario</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="long_input">
      <mat-label>Actividad</mat-label>
      <input [formControl]="activityControl" #activityInput type="text"
      placeholder="Selecciona o escribe tu actividad" matInput [matAutocomplete]="auto" 
      (input)="filterActivity()" (focus)="focusActivity()">
      <mat-icon matSuffix class="arrow-icon">arrow_drop_down</mat-icon>
      <mat-autocomplete #matAutocomplete requireSelection #auto="matAutocomplete" [displayWith]="displayFn" 
        (optionSelected)="setActivity($event.option.value)">
        @for (option of filteredOptions; track option) {
          <mat-option [value]="option">{{option.name}}</mat-option>
          }
      </mat-autocomplete>
      <mat-error *ngIf="activityControl.invalid">Debe rellenar la actividad</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" *ngIf="_isIndividual">
      <mat-label>Actividad</mat-label>
      <mat-select name="activity" formControlName="activity">
        <mat-option *ngFor="let item of cnoList" [value]="item.code">
          - {{ item.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="!personalData.get('activity')?.valid">Debe rellenar la actividad</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" *ngIf="_isIndividual">
      <mat-label>Nivel de ingresos</mat-label>
      <mat-select name="income" formControlName="income">
        <mat-option *ngFor="let item of incomeSelect" [value]="item.value">
          {{ item.viewValue }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="!personalData.get('income')?.valid">Debe introducir una cantidad</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" *ngIf="_isIndividual">
      <mat-label>Crea un PIN</mat-label>
      <input id="dni" type="password" matInput formControlName="password" maxlength="6" />
      <mat-error *ngIf="!personalData.get('password')?.valid">Debe contener 6 números</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" *ngIf="_isIndividual">
      <mat-label>Confirma el PIN</mat-label>
      <input type="password" matInput formControlName="rePassword" maxlength="6" />
      <mat-error *ngIf="personalData.get('rePassword')?.hasError('rePasswordError')">El PIN debe coincidir</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" *ngIf="_isPartner">
      <mat-label>Porcentaje participación</mat-label>
      <input type="number" matInput formControlName="equity" />
      <mat-icon matSuffix>percent</mat-icon>
      <mat-error *ngIf="!personalData.get('equity')?.valid">Debe introducir un porcentaje
      </mat-error>
    </mat-form-field>
    <div class="pepRole" *ngIf="_isRepresentative">
      <p>¿Es una Persona Expuesta Políticamente?</p>
      <mat-radio-group [(ngModel)]="hasPepRole" [ngModelOptions]="{standalone: true}" (change)="togglePepRole()">
        <mat-radio-button class="pepRoleItem" [value]="true">Sí</mat-radio-button>
        <mat-radio-button class="pepRoleItem" [value]="false">No</mat-radio-button>
      </mat-radio-group>
    </div>
    <mat-form-field appearance="outline" *ngIf="hasPepRole">
      <mat-label>País de ejercicio</mat-label>
      <mat-select name="pepCountry" formControlName="pepCountry">
        <mat-option *ngFor="let item of country" [value]="item.value">
          {{ item.viewValue }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="!personalData.get('pepCountry')?.valid">Debe introducir una nacionalidad</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" *ngIf="hasPepRole">
      <mat-label>Cargo</mat-label>
      <mat-select name="activity" formControlName="pepRole">
        <mat-option *ngFor="let item of pepRoleList" [value]="item.code">
          - {{ item.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="!personalData.get('pepRole')?.valid">
        Debe rellenar la actividad
      </mat-error>
    </mat-form-field>
    @if(isEmployee) {
    <mat-form-field appearance="outline">
      <mat-label>Permiso</mat-label>
      <mat-select formControlName="role">
        <!-- <mat-option value="admin">Administrador</mat-option> -->
        <mat-option value="employee">Empleado</mat-option>
      </mat-select>
      <mat-error *ngIf="!personalData.get('role')?.valid">Seleccione permiso
      </mat-error>
    </mat-form-field>
    }


  </form>
  <button *ngIf=" _isPartner || isAdministrator"
    [disabled]="(!personalData.valid && !isAdministrator) || (isAdministrator && !autoFill && !personalData.valid)"
    (click)="onSubmit()" class="gold-button">
    Guardar
  </button>

</div>