<div class="company-data-content">
  <h1>Subir documento</h1>
  <form [formGroup]="uploadForm" class="company-data-form" (ngSubmit)="onSubmit()">
    @if (!uploadStatusSuccess) {
    <div class="first">
      <div class="form-input upload-section">
        <h3>Sube el documento del DNI</h3>
        <div class="upload-container">
          <div class="upload-container-front">
            <h4>Parte Frontal</h4>
            <div class="button-container">
              <input type="file" (change)="onFileChange($event, 'front')" accept="image/*" id="fileInputFront" />
              <label for="fileInputFront" class="button-upload">
                <span>{{ frontFileName ? 'Cambiar Documento' : 'Seleccionar Documento' }}</span>
                <span>Frontal</span>
              </label>
            </div>
            <div class="img-preview" *ngIf="frontPreviewUrl">
              <img [src]="frontPreviewUrl" alt="Vista previa frontal" />
              <p>Parte frontal - {{ frontFileName }}</p>
            </div>
          </div>

          <div class="upload-container-back">
            <h4>Parte Trasera</h4>
            <div class="button-container">
              <input type="file" (change)="onFileChange($event, 'back')" accept="image/*" id="fileInputBack" />
              <label for="fileInputBack" class="button-upload">
                <span>{{ backFileName ? 'Cambiar Documento' : 'Seleccionar Documento' }}</span>
                <span>Trasera</span>
              </label>
            </div>
            <div class="img-preview" *ngIf="backPreviewUrl">
              <img [src]="backPreviewUrl" alt="Vista previa trasera" />
              <p>Parte trasera - {{ backFileName }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  }

    <div *ngIf="verificationStatus" class="verification-status">
      @if(!uploadStatusSuccess) {
      <p class="error">{{ verificationStatus }}</p>
      } @else {
      <app-alerts [alertStatus]="'ok'" [alertMessage]="verificationStatus"></app-alerts>
      }

    </div>
    <div class="button-next">
      @if (!uploadStatusSuccess) {
      <button type="button" class="stroked-gold-button" (click)="resetForm()">Cancelar</button>
      <button type="submit" class="gold-button" [disabled]="!uploadForm.valid || loading">
        {{ loading ? 'Subiendo...' : 'Subir' }}
      </button>
      }
    </div>
  </form>
</div>