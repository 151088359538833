<div class="operations-container">
  <section>
    <section class="info-container">
      <h2>
        Remitance Id: <span>{{ remittanceData?.sefideRemittanceId }}</span>
      </h2>
    </section>
  </section>

  <section>
    <section class="details-container">
      <div class="detail-box">
        <div class="detail-header">Estado</div>
        <div class="detail-value">
          {{ translateState(remittanceData?.status) || "Desconocido" }}
        </div>
      </div>
      <div class="detail-box">
        <div class="detail-header">Importe Total</div>
        <div class="detail-value">
          {{ remittanceData?.totalAmount | currency : "EUR" }}
        </div>
      </div>
      <div class="detail-box">
        <div class="detail-header">Última Actualización</div>
        <div class="detail-value">
          {{ formatDate(remittanceData?.updatedAt) }}
        </div>
      </div>
    </section>

    <section class="table-container" *ngIf="!loader">
      <h2 class="table-title">Detalles de Transferencias</h2>
      <div class="tableMaterial">
        <table mat-table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="externalReference">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Referencia
            </th>
            <td mat-cell *matCellDef="let element" class="pointer gold">
              {{ element.externalReference }}
            </td>
          </ng-container>

          <ng-container matColumnDef="concept">
            <th mat-header-cell *matHeaderCellDef>Concepto</th>
            <td mat-cell *matCellDef="let element">{{ element.concept }}</td>
          </ng-container>

          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef>Importe</th>
            <td mat-cell *matCellDef="let element">
              {{ element.amount | currency : "EUR" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="currency">
            <th mat-header-cell *matHeaderCellDef>Moneda</th>
            <td mat-cell *matCellDef="let element">{{ element.currency }}</td>
          </ng-container>

          <ng-container matColumnDef="transferDate">
            <th mat-header-cell *matHeaderCellDef>Fecha de Transferencia</th>
            <td mat-cell *matCellDef="let element">
              {{ formatDate(element.transferDate) }}
            </td>
          </ng-container>

          <ng-container matColumnDef="transferType">
            <th mat-header-cell *matHeaderCellDef>Tipo de Transferencia</th>
            <td mat-cell *matCellDef="let element">
              {{ element.transferType }}
            </td>
          </ng-container>

          <ng-container matColumnDef="remitterName">
            <th mat-header-cell *matHeaderCellDef>Remitente</th>
            <td mat-cell *matCellDef="let element">
              {{ element.remitterName }}
            </td>
          </ng-container>

          <ng-container matColumnDef="beneficiaryName">
            <th mat-header-cell *matHeaderCellDef>Beneficiario</th>
            <td mat-cell *matCellDef="let element">
              {{ element.beneficiaryName }}
            </td>
          </ng-container>

          <ng-container matColumnDef="situation">
            <th mat-header-cell *matHeaderCellDef>Estado</th>
            <td mat-cell *matCellDef="let element">
              <p [ngClass]="getStatusClass(element.situation)">
                {{ translateSituation(element.situation) }}
              </p>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let element; columns: displayedColumns"></tr>
        </table>
      </div>
    </section>

    <div class="no-data-message" *ngIf="transfersLoaded && dataSource.data.length === 0">
      <p>No hay datos disponibles para esta remesa.</p>
    </div>
    
  </section>

  <div class="skeleton-container" *ngIf="loader">
    <ngx-skeleton-loader
      count="{{ offset - 1 }}"
      appearance="line"
      [theme]="{ height: '76px' }"
    ></ngx-skeleton-loader>
  </div>
</div>
