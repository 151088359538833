<div class="page-content">
  <div class="content">
    <app-alerts
          *ngIf="showAlert"
          [alertMessage]="alertMessage"
          [alertStatus]="alertStatus"
          (showBtnClose)="hideAlert()"
        ></app-alerts>
    <button class="gold-button" (click)="goBackToTable()">Volver</button>
    <section class="detail-container">
      <h1>
        Operación <span>{{ this.operationInfo.codeOperation }}</span>
      </h1>
      <div class="status-btns">

          <select [(ngModel)]="selectedState" (change)="openStateChangeModal($event.target)">
            <option [value]="selectedState">{{stateFinancing(selectedState)}}</option>
            @for (item of stateSelectList.availableStates; track $index) {
            <option [value]="item">
              {{ stateFinancing(item) }}
            </option>
            }
          </select>

          <ng-container>
            <div class="unity">
              <select
                class="fr-input-salespoint"
                style="width: 220px;"
                [(ngModel)]="selectedSalesPointId"
                (ngModelChange)="onSalesPointChangeFromId($event)"
              >
                <option *ngFor="let point of salesPointsList" [value]="point.id">
                  {{ point.name }}
                </option>
              </select>
            </div>
          </ng-container>
      </div>
    </section>

    <!-- CREACIÓN, TIPO Y ESTADO FINANCIACIÓN -->
    <section class="status-container">
      <div class="info-status">
        <h4 class="bold">Fecha creación</h4>
        <h4 class="left-align">
          {{ this.operationInfo.date | date:'d/MM/yyyy H:mm' }}
        </h4>
      </div>
      <div class="info-status">
        <h4 class="bold">Tipo financiación</h4>
        <h4 class="left-align">{{ this.operationInfo.type }}</h4>
      </div>
      <div class="info-status">
        <h4 class="bold">Estado</h4>
        <h4 class="left-align">
          {{ stateFinancing(this.previousState) }}
        </h4>
      </div>
    </section>

    <!-- TABLAS IZQUIERDA PANTALLA -->
    <div class="info-container margin-left">
      <!-- DATOS DEL COMERCIAL -->
      <section class="client-data">
        <section class="info-contact">
          <app-info-table *ngIf="!loader" [infoTable]="infoComercial"></app-info-table>
          <div class="skeleton-container" *ngIf="loader">
            <ngx-skeleton-loader count="{{ offset - 1 }}" appearance="line" [theme]="{ height: '30px' }">
            </ngx-skeleton-loader>
          </div>
        </section>
        <section class="info-contact">
          <app-info-table *ngIf="!loader" [infoTable]="salesPoint"></app-info-table>
          <div class="skeleton-container" *ngIf="loader">
            <ngx-skeleton-loader count="{{ offset - 1 }}" appearance="line" [theme]="{ height: '30px' }">
            </ngx-skeleton-loader>
          </div>
        </section>

        <!--DATOS DEL CLIENTE -->
        <div class="client-data">
          <section class="info-contact">
            <div *ngIf="!loader">
              <app-info-table [infoTable]="infoClient" (editEvent)="onEditField($event, this.infoClient)"></app-info-table>
            </div>
            <div class="skeleton-container" *ngIf="loader">
              <ngx-skeleton-loader count="{{ offset - 1 }}" appearance="line" [theme]="{ height: '30px' }">
              </ngx-skeleton-loader>
            </div>

            <!-- DIRECCÓN FACTURACIÓN -->
            <app-info-table *ngIf="!loader" [infoTable]="infoAddress" (editEvent)="onEditField($event, this.infoAddress)"></app-info-table>
            <div class="skeleton-container" *ngIf="loader">
              <ngx-skeleton-loader count="{{ offset - 1 }}" appearance="line" [theme]="{ height: '30px' }">
              </ngx-skeleton-loader>
            </div>
            <!-- DIRECCIÓN DE ENVÍO -->
            <app-info-table *ngIf="!loader" [infoTable]="infoShippingAddress" (editEvent)="onEditField($event, this.infoShippingAddress)"></app-info-table>
            <div class="skeleton-container" *ngIf="loader">
              <ngx-skeleton-loader count="{{ offset - 1 }}" appearance="line" [theme]="{ height: '30px' }">
              </ngx-skeleton-loader>
            </div>
          </section>
        </div>
      </section>
      <!--   TABLAS DERECHA PANTALLA -->
      <section class="client-data">
        <!--     TABLA DE PRODUCTOS -->
        <legend class="legend margin-title">
          <p class="info_title">
            <mat-icon>inventory</mat-icon>
            Productos ({{ products.length }})
          </p>
        </legend>
        <section class="info-contact" *ngFor="let product of products">
          <ng-container>
            <app-info-table *ngIf="!loader" [infoTable]="getProductInfo(product)" ></app-info-table>
            <div class="skeleton-container" *ngIf="loader">
              <ngx-skeleton-loader count="{{ offset - 1 }}" appearance="line" [theme]="{ height: '30px' }">
              </ngx-skeleton-loader>
            </div>
          </ng-container>
        </section>
        <!--     TABLA CONDICIONES DE FINANCIACIÓN -->
        <div class="client-data">
          <section class="info-contact">
            <app-info-table *ngIf="!loader" [infoTable]="conditions" (editEvent)="onEditField($event, this.conditions)"></app-info-table>
            <div class="skeleton-container" *ngIf="loader">
              <ngx-skeleton-loader count="{{ offset - 1 }}" appearance="line" [theme]="{ height: '30px' }">
              </ngx-skeleton-loader>
            </div>
          </section>
          <!--       TABLA RESULTADO OPERACIÓN -->
          <section class="info-contact">
            <app-info-table *ngIf="!loader" [infoTable]="resume"></app-info-table>
            <div class="skeleton-container" *ngIf="loader">
              <ngx-skeleton-loader count="{{ offset - 1 }}" appearance="line" [theme]="{ height: '30px' }">
              </ngx-skeleton-loader>
            </div>
          </section>
        </div>
      </section>
    </div>

  </div>
</div>
