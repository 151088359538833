<div class="stepper">
  <div class="stepper-header">
    <div class="step" *ngFor="let step of steps; let i = index">
      <div class="step-circle-wrapper">
        <div class="step-circle" 
             [class.completed]="i < currentStep" 
             [class.active]="i === currentStep">
          <ng-container *ngIf="i < currentStep"><img src="../../../../../assets/img/svg/Check.svg" alt=""></ng-container>
          <ng-container *ngIf="i >= currentStep">{{ i + 1 }}</ng-container>
        </div>
        <div class="step-line" 
       *ngIf="i < steps.length - 1"
       [class.line-active]="i < currentStep">
  </div>
      </div>
      <div class="step-info">
        <div class="step-title">{{ step.title }}</div>
        <div class="step-subtitle">{{ step.subtitle }}</div>
      </div>
    </div>
  </div>
  @if(showAlert){
  <app-alerts [alertMessage]="alertMessage" [alertStatus]="alertStatus"></app-alerts>
  }

  <div class="step-content">
    @if (currentStep == Stepper.CARD_CONFIG && userData) {
    <app-card-configuration [userType]="role" [userData]="userData" [accounts]="accounts" (next)="nextStep()" (prev)="prevStep()" (cardsConfigEvent)="receiveProductsList($event)"></app-card-configuration>}
    @else if (currentStep == Stepper.CARD_DATA) {
    <app-card-address [userType]="role" [userData]="userData" (next)="nextStep()" (prev)="prevStep()"  (cardShippingData)="receiveCardShippingData($event)"></app-card-address>
    }@else if (currentStep == Stepper.RESUME) {
    <app-card-summary [cardConfigData]="cardConfigData" 
    [shippingData]="cardShippingData" 
    (prev)="prevStep()" 
    (confirmRequest)="handleConfirm($event)"></app-card-summary>
    }

  </div>
</div>
