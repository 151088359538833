import { UserService } from 'src/app/core/services/user/user.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, Input, ViewChild } from '@angular/core';
import { AccountsList } from 'src/app/core/models/user/userData';
import { FilterComponent } from 'src/app/shared/components/filter/filter.component';
import { saveAs } from 'file-saver';
import * as ExcelJS from 'exceljs';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CreateAccountModalComponent } from './create-account-modal/create-account-modal.component';

@Component({
  selector: 'app-user-accounts',
  templateUrl: './user-accounts.component.html',
  styleUrls: ['./user-accounts.component.scss']
})
export class UserAccountsComponent {
  public _userData: any;
  public userAccounts!: AccountsList[]
  public selectedAccountId!: string;
  public filterParams?: HttpParams;
  public loader!: boolean;
  public allMovements: any = [];
  public ibanAccount: any;
  public userName!: string;

  @ViewChild(FilterComponent) filterComponent!: FilterComponent;
  
  @Input() set userData(val:any){
  if(!val){
    return
  }else {
   this._userData = val
   console.log(this._userData.id)
  }
}

  constructor(private userService: UserService, private http: HttpClient, private datePipe: DatePipe, private router: Router, private dialog: MatDialog) {
  }

  ngOnInit(): void {
    const userType = localStorage.getItem('type')
    this.userName = userType === 'Individual' ? this._userData.fullName : this._userData.name
    this.userService.getAllAccounts(this._userData.id).subscribe(res => {
      this.userAccounts = res.filter(item => item.state === 'ACTIVATED');
    })

  }

  openCreateAccountModal() {
    const dialogRef = this.dialog.open(CreateAccountModalComponent, {
      disableClose: true,
      data: { userData: this._userData }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('Cuenta creada:', result);
      }
    });
  }
  receiveSelectedAccount(id:string){
    this.selectedAccountId = id
    const selectedAccount = this.userAccounts.find(item => item.id === this.selectedAccountId);
    if (selectedAccount) {
      this.ibanAccount = selectedAccount.iban;
      this.allMovements = []
    } else {
      this.ibanAccount = null;
    }
  }

  receiveFilters(params: HttpParams) {
    this.filterParams = params;
    this.allMovements = []
  }

  resetFilters() {
    this.filterComponent.resetAll()
  }

  receiveMovements(movements: any) {
    this.allMovements.push(...movements)
  }

  getCurrentDate(): string {
    const today = new Date();
    return today.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false })
  }

  formatCurrency(value: number): string {
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    };
    let formattedValue = value.toFixed(2);
    const [integerPart, decimalPart] = formattedValue.split('.');
    formattedValue = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    formattedValue = `${formattedValue},${decimalPart}`;
    formattedValue = `${formattedValue}€`;

    return formattedValue;
  }

  exportAsExcelFile(data: any[], excelFileName: string, titular: string, cuenta: string): void {
    const templatePath = 'assets/templates/movimientos.xlsx'; // Ruta a tu plantilla de Excel

    this.http.get(templatePath, { responseType: 'arraybuffer' }).subscribe(
      (arrayBuffer: ArrayBuffer) => {
        const workbook = new ExcelJS.Workbook();
        workbook.xlsx.load(arrayBuffer).then(() => {
          const worksheet = workbook.getWorksheet(1); // Obtener la primera hoja

          if (!worksheet) {
            console.error('Worksheet not found');
            return;
          }

          // Escribir titular en la celda D1
          worksheet.getCell('D1').value = titular;

          // Escribir número de cuenta en la celda D2
          worksheet.getCell('D2').value = cuenta;

          // Escribir fecha de descarga en la celda D3
          const currentDate = this.getCurrentDate();
          worksheet.getCell('D3').value = currentDate;

          // Escribir los datos de los movimientos a partir de la fila 6
          data.forEach((row, index) => {
            const dataRow = worksheet.getRow(6 + index);
            dataRow.values = [
              row['Fecha contable'],
              row['Fecha valor'],
              row['Descripción'],
              this.formatCurrency(parseFloat(row['Importe'])),
              this.formatCurrency(parseFloat(row['Saldo']))
            ];
          });

          // Ajustar el ancho de las columnas C, D y E a partir de la fila 5
          const columnsToAdjust = [3, 4, 5]; // Columnas C, D y E (índices 3, 4 y 5)

          columnsToAdjust.forEach(colIndex => {
            let maxLength = 10; // Mínimo ancho por defecto
            worksheet.eachRow((row) => {
              const cell = row.getCell(colIndex);
              const cellValue = cell && cell.value ? cell.value.toString() : '';
              maxLength = Math.max(maxLength, cellValue.length);

            });
            worksheet.getColumn(colIndex).width = maxLength + 2; // Añadir espacio adicional
          });

          // Guardar el archivo Excel
          workbook.xlsx.writeBuffer().then(buffer => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, `${excelFileName}.xlsx`); // Guardar como .xlsx
          }).catch(error => {
            console.error('Error al escribir buffer del Excel:', error);
          });
        }).catch(error => {
          console.error('Error al cargar el workbook:', error);
        });
      },
      (error) => {
        console.error('Error cargando la plantilla:', error);
      }
    );
  }


  exportToExcel(): void {

    const data = this.allMovements.map((movement: any) => {
      return {
        'Fecha contable': this.datePipe.transform(movement.date, 'dd-MM-YYYY'),
        'Fecha valor': this.datePipe.transform(movement.date, 'dd-MM-YYYY'),
        'Descripción': movement.description,
        'Importe': `${movement.amount}€`,
        'Saldo': `${movement.balance}€`
      }
    })

    this.exportAsExcelFile(data, 'Movimientos', this.userName, this.ibanAccount);
  }
}
