<h2 mat-dialog-title *ngIf="!showSignContract">Crear Nueva Cuenta</h2>
<h2 mat-dialog-title *ngIf="showSignContract">Firma de Contrato</h2>

<mat-dialog-content class="modal-content" [class.loading-active]="isLoading">
  
  <div class="loading-overlay" *ngIf="isLoading">
    <lottie-player src="../../../../assets/lottie/spinercirclesGreen.json" speed="1.4"  style="width: 150px; height: 150px;" loop background="transparent" autoplay></lottie-player>
    <p class="loading-text">Procesando, por favor espera...</p>
  </div>

  <div class="card" *ngIf="!isLoading">
    <app-alerts *ngIf="statusOk" [alertStatus]="'ok'" [alertMessage]="createdEmployeeMsg"></app-alerts>

    <mat-dialog-content class="mat-typography">
      <p *ngIf="!showSignContract">
        ¿Quieres crear una nueva cuenta?
      </p>

      <p *ngIf="showSignContract">
       Para continuar, el cliente deberá entrar en su área privada y dirigisre a la firma del contrato.
      </p>
    </mat-dialog-content>
  </div>
</mat-dialog-content>

<mat-dialog-actions *ngIf="!isLoading">
  <div class="buttons_container">
    <button class="stroked-gold-button" (click)="closeModal()" [disabled]="isLoading"> {{showSignContract ? 'CERRAR' : 'CANCELAR'}}</button>
    <button *ngIf="!showSignContract" class="gold-button" (click)="showSign()" [disabled]="isLoading">
      Crear Cuenta
    </button>
  </div>
</mat-dialog-actions>
