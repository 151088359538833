export const stateFinancing = (state: string) => {
  switch (state.toLowerCase()) {
    case 'approved':
      return 'Aprobado';
    case 'completed':
      return 'Activo';
    case 'denied':
      return 'Denegado';
    case 'pending':
      return 'Pendiente';
    case 'processing':
      return 'Tramitando';
    case 'cancelled':
      return 'Cancelado';
    default:
      return state;
  }
};

export const stateSigningDocuments = (state: string) => {
  switch (state.toLowerCase()) {
    case 'signed':
      return 'Firmado';
    case 'pending':
      return 'Pendiente';
    case 'send':
      return 'Enviado';
    case 'cancelled':
      return 'Cancelado';
    default:
      return state;
  }
};

export const stateInstallments = (state: string) => {
  switch (state.toLowerCase()) {
    case 'pending':
      return 'Pendiente';
    case 'overdue':
      return 'Vencido';
    case 'paid':
      return 'Pagado';
    default:
      return state;
  }
};

export function getStatusClass(state: string): string {
  switch (state.toLowerCase()) {
    case 'approved':
      return 'status-aprobado';
    case 'denied':
      return 'status-denegado';
    case 'pending':
      return 'status-pendiente';
    case 'completed':
      return 'status-completado';
    case 'processing':
      return 'status-firmado';
    case 'cancelled':
      return 'status-cancelado';
    default:
      return '';
  }
}
