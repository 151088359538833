export enum MOVEMENT_TYPE {
    ANUL      = 'ANUL',
    ANU_COMP  = 'ANU_COMP',
    ANU_CONM  = 'ANU_CONM',
    ANU_CONS  = 'ANU_CONS',
    ANU_DEVO  = 'ANU_DEVO',
    ANU_PROV  = 'ANU_PROV',
    ANU_RCBO  = 'ANU_RCBO',
    ANU_REM   = 'ANU_REM',
    ANU_RGRO  = 'ANU_RGRO',
    ANU_TRIB  = 'ANU_TRIB',
    COMI      = 'COMI',
    COMP      = 'COMP',
    CONM      = 'CONM',
    CONS      = 'CONS',
    DEVO      = 'DEVO',
    DEVO_RCBO = 'DEVO_RCBO',
    PAGO      = 'PAGO',
    PRE_REM   = 'PRE_REM',
    PROV      = 'PROV',
    RCBO      = 'RCBO',
    REM       = 'REM',
    RGRO      = 'RGRO',
    TRAN      = 'TRAN',
    TRIB      = 'TRIB',
    TRMO      = 'TRMO',
}

export enum SUBTYPE {
    SDD        = 'SDD',
    TRFIN      = 'TRFIN',
    TRNOM      = 'TRNOM',
    PEN        = 'PEN',
    EFI        = 'EFI',
    PRO        = 'PRO',
    RAP        = 'RAP',
    CUP        = 'CUP',
    FIN        = 'FIN',
    SCT        = 'SCT',
    CLI        = 'CLI',
    EMI        = 'EMI',
    NET        = 'NET',
    OEXT       = 'OEXT',
    PAG        = 'PAG',
    RED        = 'RED',
    TRJ        = 'TRJ',
    AUT        = 'AUT', 
    COMP       = 'COMP', 
    EXC        = 'EXC',
    NOM        = 'NOM',
    PER        = 'PER',
    TRF        = 'TRF',
    PHON_BONIF = 'PHON_BONIF',
    PHON_BONO  = 'PHON_BONO',
    PHON_RCBO  = 'PHON_RCBO',
    TJEX_PAGO  = 'TJEX_PAGO',
}

export enum CODE {
    _1040 = '1040',
    _2010 = '2010',
    _2110 = '2110',
    _1060 = '1060',
    _2030 = '2030',
    _2071 = '2071',
    _2075 = '2075',
    _2081 = '2081',
    _2082 = '2082',
    _2040 = '2040',
    _2100 = '2100',
    _8031 = '8031',
    _2091 = '2091',
    _2042 = '2042',
    _1070 = '1070',
    _2011 = '2011',
    _3010 = '3010',
    _3011 = '3011',
    _3012 = '3012'
}

export function getMovementType(type: string, subtype: string, code: string) {
    let result = {
        value: '',
    };

    switch (`${type}-${subtype}-${code}`) {
        case `${MOVEMENT_TYPE.ANUL}-${SUBTYPE.SDD}-${CODE._2030}`:
            result.value = 'Devolución recibo';
            break;
        case `${MOVEMENT_TYPE.ANUL}-${SUBTYPE.TRFIN}-${CODE._1060}`:
            result.value = 'Retrocesión Transferencia';
            break;
        case `${MOVEMENT_TYPE.ANUL}-${SUBTYPE.TRNOM}-${CODE._1060}`:
            result.value = 'Retrocesión haberes';
            break;
        case `${MOVEMENT_TYPE.ANU_COMP}-${SUBTYPE.TRJ}-${CODE._2071}`:
            result.value = 'Anulación Compra tarjeta';
            break;
        case `${MOVEMENT_TYPE.ANU_CONM}-${SUBTYPE.TRJ}-${CODE._2110}`:
            result.value = 'Anulación comisión consulta cajero';
            break;
        case `${MOVEMENT_TYPE.ANU_CONS}-${SUBTYPE.TRJ}-${CODE._2110}`:
             result.value = 'Anulación comisión consulta cajero';
            break;
        case `${MOVEMENT_TYPE.ANU_DEVO}-${SUBTYPE.TRJ}-${CODE._2075}`:
             result.value = 'Anulación Devolución compra';
            break;
        case `${MOVEMENT_TYPE.ANU_PROV}-${SUBTYPE.NOM}-${CODE._1060}`:
            result.value = 'Anulación de nómina';
            break;
        case `${MOVEMENT_TYPE.ANU_PROV}-${SUBTYPE.PEN}-${CODE._1060}`:
            result.value = 'Anulación de pensión';
            break;
        case `${MOVEMENT_TYPE.ANU_PROV}-${SUBTYPE.TRF}-${CODE._1060}`:
            result.value = 'Anulación de transferencia';
            break;
        case `${MOVEMENT_TYPE.ANU_RCBO}-${SUBTYPE.SDD}-${CODE._2030}`:
            result.value = 'Devolución recibo';
            break;
        case `${MOVEMENT_TYPE.ANU_RCBO}-${SUBTYPE.TRJ}-${CODE._2071}`:
            result.value = 'Anulación pago con tarjeta recibo';
            break;
        case `${MOVEMENT_TYPE.ANU_REM}-${SUBTYPE.SCT}-${CODE._2040}`:
            result.value = 'Anular emisión remesa transferencias';
            break;
        case `${MOVEMENT_TYPE.ANU_RGRO}-${SUBTYPE.TRJ}-${CODE._2100}`:
            result.value = 'Anulación reintegro en cajero';
            break;
        case `${MOVEMENT_TYPE.ANU_RGRO}-${SUBTYPE.TRJ}-${CODE._2110}`:
            result.value = 'Anulación comisión reintegro cajero automático';
            break;
        case `${MOVEMENT_TYPE.ANU_TRIB}-${SUBTYPE.TRJ}-${CODE._2071}`:
            result.value = 'Anulación pago de tributo';
            break;
        case `${MOVEMENT_TYPE.COMI}-${SUBTYPE.EFI}-${CODE._8031}`:
            result.value = 'Comisiones repercutidas entidad financiera';
            break;
        case `${MOVEMENT_TYPE.COMP}-${SUBTYPE.TRJ}-${CODE._2071}`:
            result.value = 'Compra tarjeta';
            break;
        case `${MOVEMENT_TYPE.CONM}-${SUBTYPE.TRJ}-${CODE._2110}`:
            result.value = 'Comisión consulta cajero';
            break;
        case `${MOVEMENT_TYPE.CONS}-${SUBTYPE.TRJ}-${CODE._2110}`:
            result.value = 'Comisión consulta cajero';
            break;
        case `${MOVEMENT_TYPE.DEVO}-${SUBTYPE.PRO}-${CODE._2081}`:
            result.value = 'Devolución';
            break;
        case `${MOVEMENT_TYPE.DEVO}-${SUBTYPE.PRO}-${CODE._2082}`:
            result.value = 'Anulación';
            break;
        case `${MOVEMENT_TYPE.DEVO}-${SUBTYPE.RAP}-${CODE._2081}`:
            result.value = 'Devolución';
            break;
        case `${MOVEMENT_TYPE.DEVO}-${SUBTYPE.RAP}-${CODE._2082}`:
            result.value = 'Anulación';
            break;
        case `${MOVEMENT_TYPE.DEVO}-${SUBTYPE.TRF}-${CODE._1060}`:
            result.value = 'Devolución de transferencia';
            break;
        case `${MOVEMENT_TYPE.DEVO}-${SUBTYPE.TRJ}-${CODE._2075}`:
            result.value = 'Devolución compra';
            break;
        case `${MOVEMENT_TYPE.DEVO_RCBO}-${SUBTYPE.SDD}-${CODE._2040}`:
            result.value = 'Devolución recibos';
            break;
        case `${MOVEMENT_TYPE.PAGO}-${SUBTYPE.CUP}-${CODE._2091}`:
            result.value = 'Compra con cupón';
            break;
        case `${MOVEMENT_TYPE.PAGO}-${SUBTYPE.FIN}-${CODE._1040}`:
            result.value = 'Compensación factura';
            break;
        case `${MOVEMENT_TYPE.PRE_REM}-${SUBTYPE.SDD}-${CODE._1040}`:
            result.value = 'Abono remesa de recibos';
            break;
        case `${MOVEMENT_TYPE.PROV}-${SUBTYPE.NOM}-${CODE._1060}`:
            result.value = 'Nómina';
            break;
        case `${MOVEMENT_TYPE.PROV}-${SUBTYPE.TRF}-${CODE._1040}`:
            result.value = 'Transferencia a su favor';
            break;
        case `${MOVEMENT_TYPE.PROV}-${SUBTYPE.TRF}-${CODE._1040}`:
            result.value = 'Transferencia a su favor';
            break;
        case `${MOVEMENT_TYPE.PROV}-${SUBTYPE.TRF}-${CODE._1060}`:
            result.value = 'Transferencia a su favor';
            break;
        case `${MOVEMENT_TYPE.RCBO}-${SUBTYPE.CLI}-${CODE._2030}`:
            result.value = 'Adeudo recibo';
            break;
        case `${MOVEMENT_TYPE.RCBO}-${SUBTYPE.SDD}-${CODE._2030}`:
            result.value = 'Adeudo recibo';
            break;
        case `${MOVEMENT_TYPE.RCBO}-${SUBTYPE.TRJ}-${CODE._2071}`:
            result.value = 'Pago con tarjeta recibo';
            break;
        case `${MOVEMENT_TYPE.REM}-${SUBTYPE.SCT}-${CODE._2040}`:
            result.value = 'Emisión remesa transferencias';
            break;
        case `${MOVEMENT_TYPE.RGRO}-${SUBTYPE.TRJ}-${CODE._2100}`:
            result.value = 'Reintegro en cajero';
            break;
        case `${MOVEMENT_TYPE.RGRO}-${SUBTYPE.TRJ}-${CODE._2110}`:
            result.value = 'Comisión reintegro cajero automático';
            break;
        case `${MOVEMENT_TYPE.TRAN}-${SUBTYPE.AUT}-${CODE._2040}`:
            result.value = 'Su orden transferencia';
            break;
        case `${MOVEMENT_TYPE.TRAN}-${SUBTYPE.CLI}-${CODE._2040}`:
            result.value = 'Transferencia';
            break;
        case `${MOVEMENT_TYPE.TRAN}-${SUBTYPE.EMI}-${CODE._2042}`:
            result.value = 'Su orden transferencia';
            break;
        case `${MOVEMENT_TYPE.TRAN}-${SUBTYPE.NET}-${CODE._2040}`:
            result.value = 'Compensación de factura';
            break;
        case `${MOVEMENT_TYPE.TRAN}-${SUBTYPE.NOM}-${CODE._2040}`:
            result.value = 'Nómina';
            break;
        case `${MOVEMENT_TYPE.TRAN}-${SUBTYPE.OEXT}-${CODE._1070}`:
            result.value = 'Transferencia';
            break;
        case `${MOVEMENT_TYPE.TRAN}-${SUBTYPE.PAG}-${CODE._2040}`:
            result.value = 'Pago directo cuenta';
            break;
        case `${MOVEMENT_TYPE.TRAN}-${SUBTYPE.RED}-${CODE._2040}`:
            result.value = 'Redención de emoney';
            break;
        case `${MOVEMENT_TYPE.TRAN}-${SUBTYPE.SDD}-${CODE._2040}`:
            result.value = 'Compensación de factura';
            break;
        case `${MOVEMENT_TYPE.TRIB}-${SUBTYPE.TRJ}-${CODE._2071}`:
            result.value = 'Pago de tributo';
            break;
        case `${MOVEMENT_TYPE.TRMO}-${SUBTYPE.AUT}-${CODE._2010}`:
            result.value = 'Traspaso';
            break;
        case `${MOVEMENT_TYPE.TRMO}-${SUBTYPE.CLI}-${CODE._2010}`:
            result.value = 'Traspaso';
            break;
        case `${MOVEMENT_TYPE.TRMO}-${SUBTYPE.CLI}-${CODE._3012}`:
            result.value = 'Transferencia';
            break;
        case `${MOVEMENT_TYPE.TRMO}-${SUBTYPE.COMP}-${CODE._2010}`:
            result.value = 'Compra con tarjeta';
            break;
        case `${MOVEMENT_TYPE.TRMO}-${SUBTYPE.COMP}-${CODE._3010}`:
            result.value = 'Compra con tarjeta';
            break;
        case `${MOVEMENT_TYPE.TRMO}-${SUBTYPE.EXC}-${CODE._2010}`:
            result.value = 'Traspaso';
            break;
        case `${MOVEMENT_TYPE.TRMO}-${SUBTYPE.EXC}-${CODE._3010}`:
            result.value = 'Traspaso';
            break;
        case `${MOVEMENT_TYPE.TRMO}-${SUBTYPE.NOM}-${CODE._2010}`:
            result.value = 'Traspaso';
            break;
        case `${MOVEMENT_TYPE.TRMO}-${SUBTYPE.NOM}-${CODE._2010}`:
            result.value = 'Nómina';
            break;
        case `${MOVEMENT_TYPE.TRMO}-${SUBTYPE.NOM}-${CODE._3012}`:
            result.value = 'Nómina';
            break;
        case `${MOVEMENT_TYPE.TRMO}-${SUBTYPE.PER}-${CODE._2010}`:
            result.value = 'Traspaso periódico';
            break;
        case `${MOVEMENT_TYPE.TRMO}-${SUBTYPE.PHON_BONIF}-${CODE._2010}`:
            result.value = 'Bonificación a cliente';
            break;
        case `${MOVEMENT_TYPE.TRMO}-${SUBTYPE.PHON_BONIF}-${CODE._3010}`:
            result.value = 'Bonificación';
            break;
        case `${MOVEMENT_TYPE.TRMO}-${SUBTYPE.PHON_BONO}-${CODE._2010}`:
            result.value = 'Bono adicional';
            break;
        case `${MOVEMENT_TYPE.TRMO}-${SUBTYPE.PHON_BONO}-${CODE._3010}`:
            result.value = 'Bono adicional';
            break;
        case `${MOVEMENT_TYPE.TRMO}-${SUBTYPE.PHON_RCBO}-${CODE._2010}`:
            result.value = 'Adeudo recibo';
            break;
        case `${MOVEMENT_TYPE.TRMO}-${SUBTYPE.PHON_RCBO}-${CODE._3010}`:
            result.value = 'Adeudo recibo';
            break;
        case `${MOVEMENT_TYPE.TRMO}-${SUBTYPE.SDD}-${CODE._2010}`:
            result.value = 'Compensación de factura';
            break;
        case `${MOVEMENT_TYPE.TRMO}-${SUBTYPE.TJEX_PAGO}-${CODE._2010}`:
            result.value = 'Pago tarjeta';
            break;
        case `${MOVEMENT_TYPE.TRMO}-${SUBTYPE.TJEX_PAGO}-${CODE._2011}`:
            result.value = 'Falta de saldo Pago tarjeta';
            break;
        case `${MOVEMENT_TYPE.TRMO}-${SUBTYPE.TJEX_PAGO}-${CODE._3010}`:
            result.value = 'Pago tarjeta';
            break;
        case `${MOVEMENT_TYPE.TRMO}-${SUBTYPE.TJEX_PAGO}-${CODE._3011}`:
            result.value = 'Falta de saldo Pago tarjeta';
            break;
        default:
            break;
    }
    return result.value;
}