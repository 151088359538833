import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CoreService } from 'src/app/core/services/userArea/core.service';
import { UserService } from 'src/app/core/services/userArea/user.service';
import { catchError, of, tap } from 'rxjs';
import { SpinnerComponent } from 'src/app/shared/layout/spinner/spinner.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-account-modal',
  templateUrl: './create-account-modal.component.html',
  styleUrls: ['./create-account-modal.component.scss']
})
export class CreateAccountModalComponent {
  public role: string = 'particulares';
  public autoLogin: string = sessionStorage.getItem("token") ?? '';
  public showSignContract: boolean = false;
  public accountData: any = null;
  public userData: any;
  public isLoading: boolean = false; // Controla el loader
  public statusOk: boolean = false; // Controla la alerta de éxito
  public createdEmployeeMsg: string = ''; // Mensaje de alerta

  constructor(
    public dialogRef: MatDialogRef<CreateAccountModalComponent>,
    private userService: UserService,
    private newAccountService: CoreService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: { userData: any }
  ) {
    this.userData = data.userData;
    this.detectRoleFromUrl();
  }

  closeModal() {
    this.dialogRef.close();
  }

  private detectRoleFromUrl() {
    const currentUrl = window.location.href;
    this.role = currentUrl.includes('/empresas') ? 'empresas' : 'particulares';
  }

  showSign() {
    if (!this.userData || !this.userData.id) {
      this.snackBar.open('Error: No se encontraron los datos del usuario.', 'Cerrar', { duration: 3000 });
      return;
    }

    this.isLoading = true;
    this.createdEmployeeMsg = 'Creando tu cuenta, por favor espera...';
    this.statusOk = true;

    this.newAccountService.createAccount(this.userData.id).subscribe({
      next: (response) => {
        this.accountData = response.id;
        this.showSignContract = true;
        this.isLoading = false;
        this.createdEmployeeMsg = 'Cuenta creada con éxito.';
        this.snackBar.open('Cuenta creada con éxito.', '', { duration: 4000 });
      },
      error: (error) => {
        this.isLoading = false;
        this.statusOk = false;
        this.snackBar.open('Error al crear la cuenta. Inténtalo de nuevo.', 'Cerrar', { duration: 4000 });
        console.error('Error al crear la cuenta:', error);
      }
    });
  }

}
