import { Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { UserData } from 'src/app/core/models/user/userData';
import { UserService } from 'src/app/core/services/user/user.service';
import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { FilterParams } from 'src/app/core/models/filters/filters.model';
registerLocaleData(localeEs, 'es');

@Component({
  selector: 'app-dashboard-individual',
  templateUrl: './dashboard-individual.component.html',
  styleUrls: ['./dashboard-individual.component.scss']
})
export class DashboardIndividualComponent {
  public usersList!: UserData[];
  public accumulatedData: UserData[] = [];
  public dataSource: any = [];
  public displayedColumns: string[] = [/* 'link', */ 'fullName', 'documentType', 'identifier', 'createdAt', 'updatedAt', 'status'];
  public querySearch = new FormControl();
  public loader: boolean = false;
  public offset: number = 10;
  public responseUser: any;
  public currentPage: number = 0;
  public userFilters: FilterParams = { active: true };
  public query: string = '';
  public stateValue = "active";

  public placeholder: string = "Nombre, nº DNI...";

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(private userService: UserService, private router: Router) {}

  ngOnInit(): void {
    this.setPageAndSize();
    this.getUsers();
  }

  setQuerySearch(query: FormControl) {
    this.query = query.value;
    if (query.value.length >= 1) {
      this.userFilters.query = query.value;
    } else {
      delete this.userFilters.query;
    }
    this.setPageAndSize();
    this.getUsers();
  }

  fetchUsers() {
    this.loader = true;
    this.userService.getUsersList(this.userFilters).subscribe(
      res => {
        this.responseUser = res;
        this.usersList = res.data;
        this.processUserList();
        this.loader = false;
      },
      error => {
        console.error(error);
        this.loader = false;
      }
    );
  }

  processUserList() {
    for (let user of this.usersList) {
      user['status'] = 'Activo';
      user['kyc'] = '1';
      user['verifyId'] = 'Verificado';
    }
    this.updateDataSource();
  }

  updateDataSource() {
    this.dataSource = new MatTableDataSource(this.usersList);
    setTimeout(() => {
      this.loader = false;
    }, 800);
  }

  getUser(userId: string, fullName: string) {
   /*  localStorage.setItem('userId', userId);
    localStorage.setItem('userName', fullName); */
    localStorage.setItem('type', 'Individual');
    this.router.navigate(['/particulares', userId]);
  }

  receiveFilters(filters: FilterParams) {
    this.userFilters = filters;
    this.stateValue == "active" ? this.userFilters.active = true : this.userFilters.active = false;
    if (this.query) {
      this.userFilters.query = this.query;
    } else {
      delete this.userFilters.query;
    }
    this.setPageAndSize();
    this.getUsers();
  }

  onPageChange(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.userFilters.page = event.pageIndex + 1;
    this.getUsers();
  }

  setPageAndSize() {
    this.userFilters.page = 1;
    this.userFilters.size = this.offset;
  }

  activeFilter() {
    this.userFilters.active = !this.userFilters.active;
    this.getUsers();
  }

  getUsers() {
    this.fetchUsers();
  }
}
