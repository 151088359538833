<div class="input-type">
  <app-search-bar [placeholder]="placeholder" (searchEvent)="onSearch($event)"></app-search-bar>
  <img src="../../../../../assets/img/svg/Search.svg" alt="">
</div>

<div class="tableMaterial">
  <table *ngIf="!loader" mat-table [dataSource]="sortedData" class="table-container" matSort (matSortChange)="sortData($event)">

    <ng-container matColumnDef="sefideRemittanceId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>ID Remesa</th>
      <td mat-cell *matCellDef="let element" class="pointer gold"> 
        <p>{{element.sefideRemittanceId}}</p> 
      </td>
    </ng-container>

    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha Creación</th>
      <td mat-cell *matCellDef="let element" class="pointer"> 
        <p>{{element.createdAt | date: "dd/MM/yyyy HH:mm"}}</p> 
      </td>
    </ng-container>

    <ng-container matColumnDef="updatedAt">
      <th mat-header-cell *matHeaderCellDef> Última Actualización </th>
      <td mat-cell *matCellDef="let element" class="pointer"> 
        <p>{{element.updatedAt | date: "dd/MM/yyyy HH:mm"}}</p> 
      </td>
    </ng-container>

    <ng-container matColumnDef="totalAmount">
      <th mat-header-cell *matHeaderCellDef> Importe (€) </th>
      <td mat-cell *matCellDef="let element" class="pointer"> 
        <p>{{element.totalAmount | currency : "EUR"}}</p> 
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef> Estado </th>
      <td mat-cell *matCellDef="let element" class="pointer">
        <p [ngClass]="getStatusClass(element.status)">{{ translateState(element.status) }}</p>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;"
        (click)="redirectToRemittanceDetail(element.sefideRemittanceId)">
    </tr>
  </table>

  <div class="skeleton-container" *ngIf="loader">
    <ngx-skeleton-loader count="{{offset - 1}}" appearance="line" [theme]="{ height: '36px' }">
    </ngx-skeleton-loader>
  </div>

  <mat-paginator #paginator
    class="demo-paginator"
    [length]="length"
    [pageSize]="pageSize"
    [pageIndex]="pageIndex"
    [pageSizeOptions]="pageSizeOptions"
    [showFirstLastButtons]="true"
    (page)="handlePageEvent($event)">
  </mat-paginator>
</div>
