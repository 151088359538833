import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrl: './confirm-modal.component.scss'
})
export class ConfirmModalComponent {

  public message!: string;
  public cancel: string = 'cancelar';
  public confirm: string = 'confirmar';
  public actionFunction: Function | undefined;
  public isLoading!: boolean;
  public hasInput!: boolean;
  public inputValue!: string;
  public closeFromParent!: boolean;
  public errorMessage!: string

  constructor(public dialogRef: MatDialogRef<ConfirmModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.message = data.message;
    this.cancel = data.cancel;
    this.confirm = data.confirm
    this.actionFunction = data.actionFunction
    this.hasInput = this.data.inputRequired
    this.closeFromParent = data.closeFromParent
    this.errorMessage = data.errorMessage


  }

  launchFunction() {
    if (this.actionFunction) {
      this.isLoading = true;
      this.actionFunction(this.hasInput? this.inputValue : null);
    }

    if(!this.closeFromParent){
      setTimeout(() => {
        this.dialogRef.close({data:true});
          
        }, 1000);
    }
  }

  updateMessage(errorMessage: string) {
    this.errorMessage = errorMessage; // Actualiza dinámicamente el mensaje
    this.isLoading = false
  }

}
