import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CardDetail } from '../../models/cards/card';

@Injectable({
  providedIn: 'root',
})
export class CardService {
  constructor(private http: HttpClient) {}

  activateCard(
    userId: string,
    accountId: string,
    cardId: string,
    body?: any
  ): Observable<any> {
    return this.http.post<any>(
      `${environment.coreUrl}/${userId}/account/${accountId}/card/${cardId}/activate`,
      body
    );
  }

  deleteCard(userId: string, accountId: string, cardId: string): Observable<any> {
    return this.http.delete<any>(
      `${environment.coreUrl}/${userId}/account/${accountId}/card/${cardId}`
    );
  }

  getPin(userId: string, accountId: string, cardId: string): Observable<any> {
    return this.http.get<any>(
      `${environment.coreUrl}/${userId}/account/${accountId}/card/${cardId}/pin`
    );
  }

  getAllCards(userId: string): Observable<CardDetail[]> {
    return this.http.get<CardDetail[]>(`${environment.coreUrl}/${userId}/card`);
  }

  getCard(
    userId: string,
    accountId: string,
    cardId: string
  ): Observable<CardDetail> {
    return this.http.get<CardDetail>(
      `${environment.coreUrl}/${userId}/account/${accountId}/card/${cardId}`
    );
  }

  createCard(userId: string, accountId: string, cardData: any): Observable<any> {
    return this.http.post<any>(
      `${environment.coreUrl}/${userId}/account/${accountId}/cards`,
      cardData
    );
  }

  updateCard(
    userId: string,
    accountId: string,
    cardId: string,
    cardData: any
  ): Observable<any> {
    return this.http.put<any>(
      `${environment.coreUrl}/${userId}/account/${accountId}/card/${cardId}`,
      cardData
    );
  }

  patchCard(
    userId: string,
    accountId: string,
    cardId: string,
    cardData: any
  ): Observable<any> {
    return this.http.patch<any>(
      `${environment.coreUrl}/${userId}/account/${accountId}/card/${cardId}`,
      cardData
    );
  }
}
