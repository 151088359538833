@if (!showVerification && !showEmployeeDetail) {
 <div class="table-container">
    @if(allEmployees) {
    <div class="tableMaterial table-content">
        <table mat-table [dataSource]="allEmployees">
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Nombre</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.name }} {{ element.surname1 }} {{ element.surname2 }}
                </td>
            </ng-container>
            <ng-container matColumnDef="role">
                <th mat-header-cell *matHeaderCellDef>Tipo de usuario</th>
                <td mat-cell *matCellDef="let element">
                   {{ element.role ?  element.role : 'Empleado'}}
                </td>
            </ng-container>
            <ng-container matColumnDef="birthdate">
                <th mat-header-cell *matHeaderCellDef>Fecha de nacimiento</th>
                <td mat-cell *matCellDef="let element">{{ element.birthdate }}</td>
            </ng-container>

            <ng-container matColumnDef="identifier">
                <th mat-header-cell *matHeaderCellDef>Documento</th>
                <td class="operation" mat-cell *matCellDef="let element">{{ element.identifier }}</td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef>Email</th>
                <td mat-cell *matCellDef="let element">{{ element.email }}</td>
            </ng-container>

            <ng-container matColumnDef="phone">
                <th mat-header-cell *matHeaderCellDef>Teléfono</th>
                <td mat-cell *matCellDef="let element">{{ element.phone }}</td>
            </ng-container>

            <ng-container matColumnDef="verify">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <p *ngIf="element.state && (element.state === 'REGISTERED' || element.state === 'PHONE_VERIFIED' || element.state === 'REJECTED')"
                        class="transparent-button" (click)="openVerifyEmployee(element)">
                        Verificar
                    </p>
                </td>
            </ng-container>
            <ng-container matColumnDef="state">
                <th mat-header-cell *matHeaderCellDef>Estado</th>
                <td mat-cell *matCellDef="let element">
                    <p [ngClass]="getStateClass(element.state)">
                        {{ stateEmployee(element.state) }}
                    </p>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let element; let row; columns: displayedColumns" (click)="getEmployeeDetail(element)"></tr>

        </table>
        <p class="align-to-table" *ngIf="allEmployees.length === 0">No hay empleados registrados.</p>
    </div>
    } @else {
    <ngx-skeleton-loader count="10" appearance="line" [theme]="{ height: '36px' }">
    </ngx-skeleton-loader>
    }
</div>   
}
@else if(showVerification) {
    <app-verify-employee-sim [employeeData]="selectedEmployee" [companyId]="business.id" (goBackToTable)="goBackToTable()"></app-verify-employee-sim>
} @else if( showEmployeeDetail) {
    <app-detail-employee (returnTableEmployee)="goBackToTable()"
    [employeeData]="employeeData" (goToVerifyEmployee)="openVerifyEmployee($event)"></app-detail-employee>
}
