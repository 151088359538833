import { Component, Injector, Input } from '@angular/core';
import { IconButton } from 'src/app/core/models/headers/link.model';
import { HeaderLinksService } from 'src/app/core/services/headerLinks/link.service';
import { BusinessService } from 'src/app/core/services/user/business.service';
import { EmployeeMockService } from 'src/app/core/services/user/employee.mock-service';


@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss']
})
export class EmployeeComponent {

  public usersData!: any;
  public businessData: any
  public offset: number = 10
  public nameCompany!: string;
  public employees: any;
  public idEmployee!: string;
  public subLinks!: any[]
  public selectedLink!: IconButton

  dynamicInjector!: Injector;
@Input() set userData(val:any){
  if (!val) {
    return
  }
  this.businessData = val
  this.dynamicInjector = Injector.create({
    providers: [{ provide: 'dynamicData', useValue: this.businessData}],
    parent: this.injector
  });
}

  constructor(private linksService: HeaderLinksService, private injector: Injector){
    this.subLinks = this.linksService.getEmployeesIconButtons()
    this.selectedLink = this.subLinks[0]
  }


  ngOnInit(): void {

  }


  receiveSelectedLink(link: IconButton){
    this.selectedLink = link
  }



}
