import { AccountsList, UserData } from 'src/app/core/models/user/userData';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatDialog } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { UserService } from 'src/app/core/services/user/user.service';
import { registerLocaleData } from '@angular/common';
import { AccountDetailComponent } from '../account-detail/account-detail.component';
import { environment } from 'src/environments/environment';
import { I } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-selector-accounts',
  templateUrl: './selector-accounts.component.html',
  styleUrls: ['./selector-accounts.component.scss'],
})
export class SelectorAccountsComponent {
  public userData!: UserData;
  public visibleAmount: boolean = true;
  public opcionSeleccionado: string = '0';
  public _userAccounts!: AccountsList[];
  public selectedOption!: string;
  public accountSelectedRemaining: FormControl = new FormControl('');
  public accountData!: AccountsList;
  public accountId!: string;
  public loader: boolean = true;
  public selectedIban: string = '';

  firstFiveAccounts: AccountsList[] = [];
  remainingAccounts: AccountsList[] = [];
  public showSelectorAccount: boolean = false;
  public selectAccountId: any;
  public totalBalance: any;
  selectedAccountId: string | undefined; 

  @Input() userId!: string;
  @Input() userName!: string;
  @Input() set userAccounts(val: AccountsList[]) {
    if (!val) {
      return;
    } else {
      this._userAccounts = val;

      this.firstFiveAccounts = this._userAccounts.slice(0, 5);
      this.selectedAccountId = this.firstFiveAccounts[0].id;
      this.totalBalance = this.firstFiveAccounts.reduce(
        (acc, item) => acc + item.availableBalance,
        0
      );
      if (this._userAccounts.length > 6) {
        this.firstFiveAccounts = this._userAccounts.slice(0, 5);
        this.remainingAccounts = this._userAccounts.slice(5);
        this.totalBalance = this.firstFiveAccounts.reduce(
          (acc, item) => acc + (item.availableBalance || 0),
          0
        ) + this.remainingAccounts.reduce(
          (acc, item) => acc + (item.availableBalance || 0),
          0
        );

        this.showSelectorAccount = true;
      }
    }
  }

  @Output() selectedAccount: EventEmitter<string> = new EventEmitter<string>();

  

  constructor(
    private userDataService: UserService,
    private dialog: MatDialog,
    private clipboard: Clipboard
  ) {}

  ngOnChanges(): void {
    if (this.userId) {
      this.onSubmit();
    }
  }

  onRadioChange(event: any): void {
    console.log('Cuenta seleccionada:', event.value);
    this.selectedAccountId = event.value;
    this.accountSelectedRemaining.reset();
    this.onSubmit();
  }

  hideShowAmount() {
    this.visibleAmount = !this.visibleAmount;
  }

  copy() {
    this.clipboard.copy(this.selectedIban);
  }

  onSubmitRemaining() {
    const accountId = this.accountSelectedRemaining.value;
    this.selectedAccountId = accountId;
    this.userDataService
      .getAccount(this.userId!, accountId!)
      .subscribe((res) => {
        this.accountData = res;
        this.loader = false;
        this.selectedAccount.emit(accountId);
        const selectedItem = this._userAccounts.find(
          (item) => item.id === accountId
        );
        this.selectedIban = selectedItem?.iban || '';
      });
  }
  
  onSubmit() {
    const accountId = this.selectedAccountId;
    this.userDataService
      .getAccount(this.userId!, accountId!)
      .subscribe((res) => {
        this.accountData = res;
        console.log('accountData', this.accountData);
        this.loader = false;
        this.selectedAccount.emit(accountId);
        const selectedItem = this._userAccounts.find(
          (item) => item.id === accountId
        );
        this.selectedIban = selectedItem?.iban || '';
      });
  }

  openDialog() {
    this.dialog.open(AccountDetailComponent, {
      data: { accountData: this.accountData, userName: this.userName },
    });
  }
}
