<div class="table_container">
  <div class="tableMaterial">
    @if(!loader){
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
      <ng-container matColumnDef="accountingDate">
        <th mat-header-cell *matHeaderCellDef>Fecha contable</th>
        <td mat-cell *matCellDef="let element">
          {{ element.date | date : "dd/MM/yyyy" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Fecha valor</th>
        <td mat-cell *matCellDef="let element">
          {{ element.date | date : "dd/MM/yyyy" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Descripción</th>
        <td mat-cell *matCellDef="let element">{{ element.description }}</td>
      </ng-container>

      <ng-container matColumnDef="amount">
        <th class="text_right" mat-header-cell *matHeaderCellDef>Importe</th>
        <td class="text_right" mat-cell *matCellDef="let element" [ngStyle]="{ color: element.amount > 0 ? 'green' : 'red' }">
          {{ element.amount | currency : "€" : "symbol" : "1.2-2" : "es" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="balance">
        <th class="text_right" mat-header-cell *matHeaderCellDef>Saldo</th>
        <td class="text_right" mat-cell *matCellDef="let element">
          {{ element.balance | currency : "€" : "symbol" : "1.2-2" : "es" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="expand">
        <th mat-header-cell *matHeaderCellDef aria-label="row actions">
          &nbsp;
        </th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button aria-label="expand row">
            @if(expandedElement !== element){
              <mat-icon >keyboard_arrow_down</mat-icon>
            }
            @if(expandedElement === element){
              <mat-icon>keyboard_arrow_up</mat-icon>
            }
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="expandedDetail">
        <td
          mat-cell
          *matCellDef="let element"
          [attr.colspan]="columnsToDisplayWithExpand.length"
        >
          <div
            class="element-detail"
            [@detailExpand]="
              element == expandedElement ? 'expanded' : 'collapsed'
            "
          >
            <div class="element-diagram">
              @if (movementDetail) {
              <div class="info-table_container">
                <table class="info_table">
                  @if(movementDetail.type === 'TRMO' && !this.hideConcept){
                    <tr>
                      <th>Concepto</th>
                      <td>{{ movementDetail.concept }}</td>
                      <!-- <td *ngIf="movementDetail.amount > 0">{{movementDetail.concept}}</td> -->
                    </tr>
                  }
                  @if(movementDetail.type === 'TRMO' && movementDetail.amount < 0){
                    <tr>
                      <th>Beneficiario</th>
                      <td>{{ movementDetail.beneficiary }}</td>
                    </tr>
                  }
                  @if(movementDetail.type === 'TRMO' && movementDetail.amount > 0){
                    <tr>
                    <th>Nombre del ordenante</th>
                    <td>{{ movementDetail.description }}</td>
                  </tr>
                  }
                  @if(getMovementType( movementDetail.type, movementDetail.subscribe, movementDetail.code ) === 'Compra tarjeta'){
                  <tr>
                    <th>Establecimiento</th>
                    <td>en {{ movementDetail.establishmentName }}</td>
                  </tr>
                  }

                  <tr>
                    <th>Importe</th>
                    <td> {{movementDetail.amount | currency : "€" : "symbol" : "1.2-2" : "es" }}
                    </td>
                  </tr>
                  <tr>
                    <th>Fecha operación</th>
                    <td>{{ movementDetail.date | date : "dd/MM/yyyy HH:mm:ss" }}
                    </td>
                  </tr>
                </table>
              </div>

              } @else {
              <lottie-player
                src="../../../../assets/lottie/loader-dots.json"
                background="transparent"
                speed="0.8"
                style="width: 100%; height: 140px"
                loop
                autoplay
                id="animacion"
                #animacion
              ></lottie-player>
              }
            </div>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: columnsToDisplayWithExpand; let i = index"
        class="element-row"
        [class.expanded-row]="expandedElement === row"
        [ngClass]="i % 2 == 0 ? 'par' : 'impar'"
        (click)="$event.stopPropagation(); toggleExpandedRow(row)"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        class="detail-row"
        [ngStyle]="{ display: expandedElement === row ? '' : 'none' }"
      ></tr>
      <!-- [ngStyle]="i % 2 == 0 ? {'background-color' : 'white'} : {'background-color' : '#e0e0e052'}"  -->
    </table>
    } @if(!loader && !hideNextButton){
    <button class="button-next" class="gold-button" (click)="nextPage()">
      Más movimientos
    </button>
    } @if(!loader && movementsData.length == 0){
    <mat-hint class="mat-hint">No hay más movimientos</mat-hint>
    } ​
    @if(loader){
      <div class="skeleton-container">
        <ngx-skeleton-loader
          count="{{ offset + accumulatedData.length }}"
          appearance="line"
          [theme]="{ height: '36px' }"
        >
        </ngx-skeleton-loader>
      </div>
    }
  </div>
</div>

