import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from 'src/app/core/services/user/user.service';
import { DocumentsService } from 'src/app/core/services/documents/documents.service';
import { tap, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-individual-contract',
  templateUrl: './individual-contract.component.html',
  styleUrls: ['./individual-contract.component.scss']
})
export class IndividualContractComponent {
  @Input() userData!: any;

  public loader: boolean = false;
  public signedContracts: any[] = [];
  public pendingContracts: any[] = [];
  public filePreview: string | null = null;
  public fileToDownload: any;
  public skeletonCount: number = 3; 
  public offset: any;

  constructor(
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private userService: UserService,
    private documentService: DocumentsService
  ) {}

  ngOnInit() {
    this.loadContracts();
  }

  loadContracts() {
    this.loader = true;
    this.userService.getAllAccounts(this.userData.id).subscribe({
      next: (res) => {
        this.signedContracts = res
          .filter(account => account.state === 'ACTIVATED')
          .map(contract => ({
            ...contract,
            date: contract.createdAt || 'Sin fecha'
          }));

        this.pendingContracts = res
          .filter(account => account.state === 'PRE_ACTIVATED') 
          .map(contract => ({
            ...contract,
            date: contract.createdAt || 'Sin fecha'
          }));

        this.loader = false;
      },
      error: (err) => {
        console.error('Error al obtener contratos:', err);
        this.loader = false;
      }
    });
}


  viewContract(contract: any) {
    this.filePreview = null;
    this.fileToDownload = null;

    this.documentService.getDocument(this.userData.id, contract.contractId).pipe(
      switchMap((res) => {
        if (res.files.length > 0) {
          const file = res.files[0];
          return this.documentService.downloadDocument(this.userData.id, contract.contractId, file.id).pipe(
            tap((res) => {
              const blob = new Blob([res], { type: 'application/pdf' });
              const url = window.URL.createObjectURL(blob);
              this.filePreview = url;
              this.fileToDownload = res;
            })
          );
        }
        return [];
      })
    ).subscribe();
  }
  downloadContract() {
    if (this.fileToDownload) {
      const blob = new Blob([this.fileToDownload], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `contrato.pdf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }
  }
}
