<div class="signature">
  <h2>Contratos y Documentos</h2>

  <div class="subtitle-container">
    <h3>Contratos Pendientes</h3>
  </div>
  <div class="tableMaterial">
    <ng-container *ngIf="!loader">
      <table *ngIf="pendingContracts.length > 0" mat-table [dataSource]="pendingContracts" class="table-container" matSort>
        <ng-container matColumnDef="iban">
          <th mat-header-cell *matHeaderCellDef>Cuenta</th>
          <td mat-cell *matCellDef="let contract">{{ contract.iban }}</td>
        </ng-container>

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>Fecha</th>
          <td mat-cell *matCellDef="let contract">{{ contract.date | date: 'dd/MM/yyyy' }}</td>
        </ng-container>

        <ng-container matColumnDef="view">
          <th mat-header-cell *matHeaderCellDef>Ver</th>
          <td mat-cell *matCellDef="let contract">
            <div class="icon-link">
              <button mat-icon-button (click)="viewContract(contract)">
                <mat-icon style="cursor:pointer">visibility</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="['iban', 'date', 'view']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['iban', 'date', 'view'];"></tr>
      </table>

      <div *ngIf="pendingContracts.length === 0" class="empty-message">
        <p>No hay contratos pendientes</p>
      </div>
    </ng-container>

    <div class="skeleton-container" *ngIf="loader">
      <ngx-skeleton-loader [count]="skeletonCount" appearance="line" [theme]="{ height: '36px' }"></ngx-skeleton-loader>
    </div>
  </div>

  <div class="subtitle-container">
    <h3>Contratos Firmados</h3>
  </div>
  <div class="tableMaterial">
    <ng-container *ngIf="!loader">
      <table *ngIf="signedContracts.length > 0" mat-table [dataSource]="signedContracts" class="table-container" matSort>

        <ng-container matColumnDef="iban">
          <th mat-header-cell *matHeaderCellDef>Cuenta</th>
          <td mat-cell *matCellDef="let contract">{{ contract.iban }}</td>
        </ng-container>

        <ng-container matColumnDef="date">  <!-- 🔹 Agregado para evitar el error -->
          <th mat-header-cell *matHeaderCellDef>Fecha</th>
          <td mat-cell *matCellDef="let contract">{{ contract.date | date: 'dd/MM/yyyy' }}</td>
        </ng-container>

        <ng-container matColumnDef="view">
          <th mat-header-cell *matHeaderCellDef>Ver</th>
          <td mat-cell *matCellDef="let contract">
            <div class="icon-link">
              <button mat-icon-button (click)="viewContract(contract)">
                <mat-icon style="cursor:pointer">visibility</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="['iban', 'date', 'view']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['iban', 'date', 'view'];"></tr>
      </table>

      <div *ngIf="signedContracts.length === 0" class="empty-message">
        <p>No hay contratos firmados</p>
      </div>
    </ng-container>

    <div class="skeleton-container" *ngIf="loader">
      <ngx-skeleton-loader [count]="skeletonCount" appearance="line" [theme]="{ height: '36px' }"></ngx-skeleton-loader>
    </div>
  </div>
</div>
