<div class="selector_background">
    <div class="selector_component">
      <div class="radio-accounts">
        <mat-radio-group
          aria-labelledby="example-radio-group-label"
          class="example-radio-group"
          [(ngModel)]="selectedAccountId"
          (change)="onRadioChange($event)"
        >
        @for(item of firstFiveAccounts; track item) {
          <mat-radio-button class="example-radio-button" [value]="item.id">
            CUENTA {{item.alias}}: {{item.iban}}
          </mat-radio-button>
        }
        </mat-radio-group>
      </div>
  
      @if(showSelectorAccount){
        <div class="selector_icons">
          <mat-form-field class="accounts" appearance="fill">
            <mat-label>Otras cuentas</mat-label>
              <mat-select style="text-transform: uppercase" [formControl]="accountSelectedRemaining" (selectionChange)="onSubmitRemaining()">
                @for(item of remainingAccounts; track item){
                  <mat-option style="text-transform: uppercase;" class="mat-option" [value]="item.id">
                  CUENTA {{item.alias}}: {{item.iban}}
                </mat-option>
                }
              </mat-select>
          </mat-form-field>
          @if(!loader){
            <button class="pointer-container" mat-icon-button [cdkCopyToClipboard]="accountData.iban" >
              <mat-icon class="pointer" >content_copy</mat-icon>
            </button>
          }
        </div>
      }
      <div class="selector_bottom" >
        <div class="selector_info">
          @if(!loader){
            <div class="money_info">
              <p>Saldo total</p>
              @if(visibleAmount){
                <h3>{{totalBalance | currency: "€":"symbol":"1.2-2":"es"}}</h3>
              }
              @if(!visibleAmount){
                <h3>******** €</h3>
              }
            </div>
          }
          @if(!loader){
            <div class="money_info">
              <p>Saldo contable</p>
              @if(visibleAmount){
                <h3>{{accountData.balance | currency: "€":"symbol":"1.2-2":"es"}}</h3>
              }
              @if(!visibleAmount){
                <h3>******** €</h3>
              }
            </div>
          }
          @if(loader){
            <div class="skeleton">
              <ngx-skeleton-loader count="1" appearance="line" [theme]="{ height: '50px' }">
              </ngx-skeleton-loader>
            </div>
          }
          @if(!loader){
            <div class="money_info">
              <p>Saldo disponible</p>
              @if(visibleAmount){
                <h3>{{accountData.balance | currency: "€":"symbol":"1.2-2":"es"}}</h3>
              } 
              @if(!visibleAmount){
                <h3>******** €</h3>
              }
            </div>
          }
          <ng-container class="icons-content">
            @if(!loader){
              <button mat-icon-button (click)="openDialog()">
                <mat-icon class="pointer">settings_suggest</mat-icon>
              </button>
            }
            @if(loader){
              <ngx-skeleton-loader count="1" appearance="circle" >
              </ngx-skeleton-loader>
            }
            @if(loader){
              <ngx-skeleton-loader count="1" appearance="circle" >
              </ngx-skeleton-loader>
            }
          </ng-container>
        </div>
      </div>
<!--       <div class="selector_actions">
        <button class="gold-button">Nuevo cargo</button>
        <button class="gold-button">Nuevo abono</button>
      </div> -->
    </div>
  </div>