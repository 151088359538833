import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Business } from '../../models/user/businessData';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BusinessService {

  constructor(private http: HttpClient) { }

  public createUser(body: Business): Observable<any> {
    return this.http.post<any>(`${environment.companyUrl}/company`, body)
  }
  public deleteCompany(companyId:string) : Observable<any>{
    return this.http.delete<any>(`${environment.companyUrl}/company/${companyId}`)
  }

  public getDocuments(id: string): Observable<any>{
    return this.http.get<any>(`${environment.companyUrl}/${id}/documents/state`)
  }

/*   public getCompanyMe(){
    return this.http.get<any>(`${environment.companyUrl}/company/me`)
  } */

  public getCompanies(params?: any): Observable<any>{
    return this.http.get<any>(`${environment.companyUrl}/companies`, { params })
  }

  public getCompany(companyId: string): Observable<any>{
    return this.http.get<any>(`${environment.companyUrl}/company/${companyId}`)
  }

  public getEmployees(companyId: string): Observable<any> {
    return this.http.get<any>(`${environment.companyUrl}/company/${companyId}/employees`)
  }
  
  public createEmployee(companyId: string, body:any): Observable<any> {
    return this.http.post<any>(`${environment.companyUrl}/company/${companyId}/employee`, body)
  }

  public uploadDocument(companyId: string, formData: FormData): Observable<any>{
    return this.http.post<any>(`${environment.companyUrl}/${companyId}/documents`, formData)
  }
  public createFormOdontologist(body:any): Observable<any> {
    return this.http.post(`${environment.contactUrl}/odontologists/contact`, body)
  }

  public requestSmsVerification(companyId: string, employeeId: string, body?: any): Observable<any> {
    return this.http.post<any>(
      `${environment.companyUrl}/company/${companyId}/employee/${employeeId}/request/sms/verification`, 
      body
    );
  }
  public verifyPhone(companyId: string, employeeId: string, otp: string): Observable<any> {
    return this.http.post<any>(
      `${environment.companyUrl}/company/${companyId}/employee/${employeeId}/verify/phone?otp=${otp}`, 
      {}
    );
  }
  
  public uploadIdentifier(companyId: string, employeeId: string, formData: FormData): Observable<any> {
    return this.http.post<any>(
      `${environment.companyUrl}/company/${companyId}/employee/${employeeId}/identifier/upload`, 
      formData
    );
  }
  
  public verifyIdentifier(companyId: string, employeeId: string, body?: any): Observable<any> {
    return this.http.post<any>(
      `${environment.companyUrl}/company/${companyId}/employee/${employeeId}/identifier/verify`, 
      body
    );
  }



}
