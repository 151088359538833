import { CurrencyPipe, DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { stateFinancing } from 'src/app/core/extensions/stateFinancing.extension';
import { AddressData } from 'src/app/core/models/financing/userFinancingData';
import { stateInstallments } from './../../../../../core/extensions/stateFinancing.extension';
import { InstallmentDetailComponent } from './installment-detail/installment-detail.component';
import { FinancingService } from 'src/app/core/services/financing/financing.service';

@Component({
  selector: 'app-amortizacion',
  templateUrl: './amortizacion.component.html',
  styleUrls: ['./amortizacion.component.scss'],
})
export class AmortizacionComponent implements OnInit {
  @Input() userData!: any;
  isUpdating: boolean = false;
  newFirstPaymentDate: string = '';
  isLoading = false;
  operationInfo: any;
  customerDetails?: AddressData;
  shippingAddress?: AddressData;
  stateInstallments = stateInstallments;
  stateFinancing = stateFinancing;
  details!: any;

  displayedColumns = [
    'Cuota',
    'Fecha de Emisión',
    'Ref.Pago',
    'Importe',
    'Resto a pagar',
    'Estado',
  ];
  dataSource = new MatTableDataSource<any>([]);
  panelOpenState = false;
  selectedCuota: number | null = null;

  constructor(
    private router: Router,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private currencyPipe: CurrencyPipe,
    private financingService: FinancingService,
     private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.operationInfo = this.userData;
    if (this.operationInfo) {
      this.loadInstallments();
      this.prepareDetails(this.operationInfo);
    } else {
      this.router.navigate(['/renting']);
    }
  }
  loadInstallments(): void {
    if (this.operationInfo?.installments?.length) {
      this.updateInstallmentTable();
    }
  }

  prepareDetails(operation: any) {
    const currentDate = new Date();
    const nextPayment = operation.installments.find((period: any) => {
      const paymentDate = new Date(period.date);
      return period.state === 'PENDING' && paymentDate > currentDate;
    });

    if (!nextPayment) {
      this.details = [
        { label: 'Estado', value: operation.state || 'Desconocido' },
        { label: 'Importe total', value: operation.totalAmount || 'N/A' },
        {
          label: 'Plazo',
          value: operation.monthsTerm ? `${operation.monthsTerm} meses` : 'N/A',
        },
        { label: 'Próximo pago', value: 'No hay pagos pendientes' },
        { label: 'Fecha pr pago', value: 'N/A' },
      ];
    } else {
      const formattedNextPaymentDate = this.datePipe.transform(nextPayment.date, 'dd/MM/yyyy');
      this.details = [
        { label: 'Estado', value: stateFinancing(operation.state) || 'Desconocido' },
        {
          label: 'Importe total',
          value: operation.totalAmount ? this.currencyPipe.transform(operation.totalAmount, 'EUR') : 'N/A',
        },
        {
          label: 'Plazo',
          value: operation.monthsTerm ? `${operation.monthsTerm} meses` : 'N/A',
        },
        { 
          label: 'Próximo pago', 
          value: this.currencyPipe.transform(nextPayment.payment + (nextPayment.payment * 0.21), 'EUR') || 'N/A' 
        },        
      { label: 'Fecha próx pago', value: formattedNextPaymentDate || 'N/A' },
      ];
    }
  }

  updateInstallmentTable(): void {
    this.dataSource.data = this.operationInfo.installments.map(
      (installment: any) => ({
        cuota: installment.period || 0,
        fechaEmision: installment.date || null,
        refPago: `PG${
          this.operationInfo?.codeOperation || 'SIN-CODIGO'
        }-${String(installment.period || '0').padStart(3, '0')}`,
        importe: installment.netPayment + installment.netPayment * 0.21 || 0,
        estado: installment.state || 'Desconocido',
        resto: installment.outstandingCapital + installment.outstandingCapital * 0.21 || 0
      })
    );
  }

  updateFirstPaymentDate(): void {
    if (!this.newFirstPaymentDate) {
      this.showAlertMessage('La fecha no puede estar vacía.', 'ko');
      return;
    }
  
    if (this.isUpdating) return;
    this.isUpdating = true;
  
    this.financingService.updateInstallmentDate(this.operationInfo.id, this.newFirstPaymentDate).subscribe({
      next: () => {
        this.onFirstPaymentUpdateSuccess(this.newFirstPaymentDate);
        this.newFirstPaymentDate = '';
      },
      error: (err) => {
        this.onFirstPaymentUpdateError(err);
      },
      complete: () => {
        this.isUpdating = false;
      }
    });
  }
  
    private onFirstPaymentUpdateSuccess(newDate: string) {
      this.showAlertMessage('Fecha de primer pago actualizada con éxito.', 'ok');
  
      if (this.operationInfo.installments.length > 0) {
        const baseDate = new Date(newDate);
  
        this.operationInfo.installments.forEach(
          (installment: any, index: number) => {
            const paymentDate = new Date(baseDate);
            paymentDate.setMonth(paymentDate.getMonth() + index);
            installment.date = this.datePipe.transform(paymentDate, 'yyyy-MM-dd');
          }
        );
      }
  
      this.updateInstallmentTable();
      this.cdr.detectChanges();
    }
    private onFirstPaymentUpdateError(err: any) {
      console.error('Error al actualizar la fecha de primer pago:', err);
      this.showAlertMessage('Error al actualizar la fecha de primer pago.', 'ko');
    }

  formatDetailValue(value: any): string {
    if (value === null || value === undefined || value === '') {
      return 'N/A';
    }
    if (typeof value === 'number') {
      return `${value.toFixed(2)} €`;
    }
    if (typeof value === 'boolean') {
      return value ? 'Sí' : 'No';
    }
    if (value instanceof Date) {
      return this.datePipe.transform(value, 'dd/MM/yyyy') || 'N/A';
    }
    return value;
  }

  openModal(element: any) {
    console.log('Datos del elemento:', element); 
    const selectedInstallment = this.operationInfo.installments.find(
      (installment: any) => installment.period === element.cuota
    );
    this.dialog.open(InstallmentDetailComponent, {
      data: {
        installment: selectedInstallment, 
        operationInfo: this.operationInfo,
      },
    });
  }
  

  getStatusClass(state: string): string {
    switch (state.toLowerCase()) {
      case 'paid':
        return 'status-aprobado';
      case 'overdue':
        return 'status-denegado';
      case 'pending':
        return 'status-pendiente';
      default:
        return '';
    }
  }

  showAlertMessage(message: string, type: string): void {
    console.log(`[${type.toUpperCase()}] ${message}`);
  }
}
