import { HttpParams } from '@angular/common/http';
import { ThisReceiver } from '@angular/compiler';
import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { tap, catchError, forkJoin } from 'rxjs';
import { EmployeeState, getStateClass, stateEmployee } from 'src/app/core/extensions/employee.extension';
import { BusinessRole } from 'src/app/core/models/user/userData';
import { DocumentsService } from 'src/app/core/services/documents/documents.service';
import { BusinessService } from 'src/app/core/services/user/business.service';

@Component({
  selector: 'app-table-employee',
  templateUrl: './table-employee.component.html',
  styleUrls: ['./table-employee.component.scss']
})
export class TableEmployeeComponent {

  public displayedColumns: string[] = ['name', 'role', 'birthdate', 'identifier', 'email', 'phone','state', 'verify'
  ];
  public allEmployees!: any[];
  public currentPage: number = 1;
  public filters: HttpParams = new HttpParams();
  public showEmployeeDetail!: boolean;
  public employeeData: any;
  public business: any;
  public showVerification!:boolean;
  public selectedEmployee!: any
  public loadingDocumentsState: boolean = false;
  stateEmployee = stateEmployee
  getStateClass = getStateClass


  constructor(@Inject('dynamicData') public businessData: any, private businessService: BusinessService, private documentsService: DocumentsService) {
   this.business = businessData

   }

  ngOnInit() {
    this.getEmployeeList()
    
  }
  
  getEmployeeList() {
    this.businessService.getEmployees(this.business.id).subscribe(res => {
      this.allEmployees = res;
      const representative = this.businessData.representative;
      representative.role = BusinessRole.REPRESENTATIVE
      representative.state = EmployeeState.DOCUMENT_VERIFIED
      const partners = this.businessData.partners.map((partner:any) => {
        partner.role = BusinessRole.PARTNER
        partner.state = EmployeeState.DOCUMENT_VERIFIED
        return partner
      });
      const administrators = this.businessData.administrators.map((admin:any) => {
        admin.role = BusinessRole.ADMINISTRATOR
        admin.state = EmployeeState.DOCUMENT_VERIFIED
        return admin
      });
      this.allEmployees.unshift( representative, ...partners, ...administrators );
      this.getEmployeesDocumentsState();
    })
    
  }
  getEmployeesDocumentsState() {
    if (!this.allEmployees || !Array.isArray(this.allEmployees)) return;

    this.loadingDocumentsState = true;

    const requests = this.allEmployees
      .filter((employee) => employee.documentIdentifier)
      .map((employee) =>
        this.documentsService.getDocument(employee.id, employee.documentIdentifier).pipe(
          tap((response) => {
            if (response?.state === 'PENDING') {
              employee.state = 'PENDING_VERIFICATION';
            } else if (response?.state === 'REJECTED') {
              employee.state = 'REJECTED';
            } 
            console.log(`Empleado ${employee.id} - Nuevo estado: ${employee.state}`);
          }),
          catchError((err) => {
            console.error(`Error obteniendo estado de documentos para empleado ${employee.id}:`, err);
            return [];
          })
        )
      );

    if (requests.length > 0) {
      forkJoin(requests).subscribe(() => {
        this.loadingDocumentsState = false;
      });
    } else {
      this.loadingDocumentsState = false;
    }
  }
  
 getEmployeeDetail (employee: any) {
  this.showVerification = false;
  this.showEmployeeDetail = true
    this.employeeData = employee
  }

  openVerifyEmployee(employee: any) {
    this.showEmployeeDetail = false
    this.showVerification = true
    this.selectedEmployee = employee
  }

  goBackToTable() {
    this.showEmployeeDetail = false
    this.showVerification = false;
    this.selectedEmployee = null
  }


}
