<div class="general-container">
    <div class="section_header">
        <div class="section_title">
            <p>{{businessData.name}} <span class="view-title">{{selectedLink.toolTip}}</span></p>
        </div>
    <app-navigation-buttons [subLinks]="subLinks" [selectedLink]="selectedLink" (selected)="receiveSelectedLink($event)"></app-navigation-buttons>
    </div>
    <div class="employee-container">
        <ng-container *ngComponentOutlet="selectedLink.component; injector: dynamicInjector"></ng-container> 
    </div>
   
</div>

