import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { stateInstallments } from './../../../../../../core/extensions/stateFinancing.extension';

@Component({
  selector: 'app-installment-detail',
  templateUrl: './installment-detail.component.html',
  styleUrls: ['./installment-detail.component.scss'],
})
export class InstallmentDetailComponent implements OnInit {
  installment: any;
  operationInfo: any;
  public infoInstallmentTable: any;
  public infoDataTable: any;
  public taxPayment: number = 0;
  public totalPayment: number = 0;

  constructor(
    private datePipe: DatePipe,
    private currencyPipe: CurrencyPipe,
    @Inject(MAT_DIALOG_DATA) data: any,
    private dialog: MatDialog
  ) {
    this.installment = data.installment;
    this.operationInfo = data.operationInfo;
  }

  ngOnInit(): void {
    this.taxPayment = this.installment.netPayment * 0.21;
    this.totalPayment = this.installment.netPayment + this.taxPayment;
    this.getInstallmentTable();
    this.getDataTable();
    console.log(this.installment);
    console.log(this.operationInfo);
    if (!this.installment) {
      console.error('No se pasaron datos de cuota al detalle');
    }
  }

  stateInstallments = stateInstallments;

  getInstallmentTable() {
    if (this.installment) {
      this.infoInstallmentTable = {
        title: 'Detalles de la cuota',
        icon: 'bar_chart',
        data: [
          {
            key: 'payment',
            name: 'Principal',
            title: 'Principal',
            value: this.currencyPipe.transform(
              this.installment.outstandingCapital,
              'EUR',
              'symbol',
              '1.2-2',
              'es'
            ),
            editable: false,
          },
          {
            key: 'interests',
            name: 'Intereses',
            title: 'Intereses',
            value: this.currencyPipe.transform(
              this.installment.interest,
              'EUR',
              'symbol',
              '1.2-2',
              'es'
            ),
            editable: false,
          },
          {
            key: 'insurance',
            name: 'Seguro',
            title: 'Seguro',
            value: this.currencyPipe.transform(
              this.installment.insurance,
              'EUR',
              'symbol',
              '1.2-2',
              'es'
            ),
            editable: false,
          },
          {
            key: 'no-iva',
            name: 'Cuota (sin IVA)',
            title: 'Cuota (sin IVA)',
            value: this.currencyPipe.transform(
              this.installment.netPayment,
              'EUR',
              'symbol',
              '1.2-2',
              'es'
            ),
            editable: false,
          },
          {
            key: 'iva',
            name: 'IVA (21%)',
            title: 'IVA (21%)',
            value: this.currencyPipe.transform(
              this.taxPayment,
              'EUR',
              'symbol',
              '1.2-2',
              'es'
            ),
            editable: false,
          },
          {
            key: 'iva',
            name: 'Cuota (con IVA)',
            title: 'Cuota (con IVA)',
            value: this.currencyPipe.transform(
              this.totalPayment,
              'EUR',
              'symbol',
              '1.2-2',
              'es'
            ),
            editable: false,
          },
        ],
      };
    }
  }

  getDataTable() {
    if (this.installment) {
      this.infoDataTable = {
        title: 'Información de la cuota',
        icon: 'info',
        data: [
          {
            key: 'financing',
            name: 'Ref. Pago',
            title: 'Ref. Pago',
            value: `PG${
              this.operationInfo?.codeOperation || 'SIN-CODIGO'
            }-${String(this.installment.period || '0').padStart(3, '0')}`,
            editable: false,
          },
          {
            key: 'installment',
            name: 'Cuota',
            title: 'Cuota',
            value: this.installment.period,
            editable: false,
          },
          {
            key: 'date',
            name: 'Fecha de cobro',
            title: 'Fecha de cobro',
            value: this.datePipe.transform(this.installment.date, 'dd/MM/yyyy'),
            editable: false,
          },

          {
            key: 'state',
            name: 'Estado',
            title: 'Estado',
            value:  stateInstallments(this.installment.state),
            editable: false,
          },
        ],
      };
    }
  }

  closeModal() {
    this.dialog.closeAll();
  }
}
