import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/services/auth.service';


@Component({
  selector: 'app-account-detail',
  templateUrl: './account-detail.component.html',
  styleUrls: ['./account-detail.component.scss']
})
export class AccountDetailComponent {

  public accountData!: any;
  public accountTable!: any[]
  public displayedColumns: string[] = ['item', 'cost'];
  public userName!: string;
  public loader!: boolean;

  constructor( @Inject(MAT_DIALOG_DATA) data: any, private auth : AuthService){
    console.log(data)
    this.userName =  data.userName
    this.accountData = data.accountData
    this.accountTable = [
      {
        title: 'IBAN',
        value: this.accountData.iban
      },
      {
        title: 'Alias',
        value: this.accountData.alias
      },
      {
        title: 'Moneda',
        value: this.accountData.currency
      },
      {
        title: 'Fecha de apertura',
        value: this.accountData.openingDate
      },
      {
        title: 'Fecha última operación',
        value: this.accountData.lastOperationDate
      },
      {
        title: 'Saldo',
        value: this.accountData.balance
      },
      {
        title: 'Disponible',
        value: this.accountData.availableBalance
      },
      {
        title: 'Código BIC/SWIFT',
        value: 'XXXXXXXXX'
      },
    ]
    
  }
}
