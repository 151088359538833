import { HttpParams } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { allRegex } from 'src/app/core/extensions/regex';
import { FilterParams } from 'src/app/core/models/filters/filters.model';
import { TransfersService } from 'src/app/core/services/transfers/transfers.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { CoreService } from 'src/app/core/services/userArea/core.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-transfers',
  templateUrl: './transfers.component.html',
  styleUrls: ['./transfers.component.scss']
})
export class TransfersComponent {
  public role!: string;
  public userId: string = environment.denariusId;
/* public userId: string = localStorage.getItem('userId')! */
  public singleAccount: boolean = false;

  constructor(private userDataService: CoreService ){}
  @Input() userData!: any;

  ngOnInit(){
    this.userDataService.getAccounts(this.userId).subscribe(res => {
      const accounts = res.filter(item => item.state === "ACTIVATED")
      if (accounts.length > 1) {
        this.singleAccount = true
      }
    })
  }

}
/* export class TransfersComponent {
  public transferForm!: FormGroup
  public senderId!: string;
  public senderName!: string
  public accounts!: any
  public initialSelection!: string;
  public identifier = new FormControl()
  public showSenderData: boolean = false;
  public loader: boolean = false;
  public disableSearch: boolean = false

  constructor(private formBuilder: FormBuilder, private userService: UserService, private transfersService: TransfersService) {
    
    this.identifier = new FormControl('',[Validators.required, Validators.pattern(allRegex.regexDNI)]),

    this.transferForm = this.formBuilder.group({
      senderName: [,[Validators.required]],
      originAccount: [,[Validators.required]],
      amount: [,[Validators.required]],
      concept: [,[Validators.required, Validators.minLength(3)]],
      receiver: [,[Validators.required]],
      destinationAccount: [,[Validators.required, Validators.pattern(allRegex.regexIban)]]
    })
  }

  ngOnInit():void {
  }

  searchIdentifier(){
    this.identifier.markAsTouched()  
    this.loader = true;
    if(this.identifier.valid){
      this.transferForm.reset()
      console.log(this.loader)
        const id = this.identifier?.value
        let idSearch: FilterParams = {}
        idSearch.identifier = id;
        console.log(id)
        this.userService.getUsersList(idSearch).subscribe(res => {
          if(res.data.length > 0){
             this.senderId = res.data[0]
             this.userService.getUser(res.data[0].id).subscribe(response => {
              console.log(response)
              this.senderName = response.fullName
              const senderId = response.id
              this.transferForm.get('senderName')?.setValue(this.senderName)
              this.userService.getAllAccounts(senderId).subscribe(accounts => {
                if(accounts.length > 0){
                   this.accounts = accounts;
                this.initialSelection = accounts[0].id
                this.transferForm.get('originAccount')?.setValue(this.initialSelection)
                console.log(this.accounts)
                this.showSenderData = true
                this.loader = false;
                console.log(this.loader)
                }else{
                  this.loader = false;
                  this.identifier?.setErrors({noAccounts: 'Este usuario no tiene cuentas'})
                  console.log(this.loader)
                }
               
              })
            })
          }else{
            console.log('No hay datos')
            this.loader = false;
            this.identifier?.setErrors({notFound: 'Este usuario no existe'})
            console.log(this.loader)
          }
        })
      }else{
        this.loader = false;
        this.identifier?.setErrors({invalid: 'Formato inválido'})
    }
   
  }

  onSubmit(){
    const data = {
      sender:{
        name:'',
        accountId: this.transferForm.get('originAccount')?.value, //PIDE UN ID
      },
      receiver:{
         name:  this.transferForm.get('receiver')?.value,
         iban: this.transferForm.get('destinationAccount')?.value.replace(/\s/g, ''),
      },
      amount: Number(this.transferForm.get('amount')?.value.replace(',','.')),
      concept: this.transferForm.get('concept')?.value,
      type: 'SEPA'
    }
    console.log(data)

    this.transfersService.postTransfer(data).subscribe(res => {
      
    })
  }
  

  preventLetters(event: KeyboardEvent) {
    const key = event.key;
    if ((key >= 'a' && key <= 'z') || key === '.') {
      event.preventDefault(); // Evitar la entrada de caracteres
    }
  }


}
 */
