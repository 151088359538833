import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { EmployeeState } from 'src/app/core/extensions/employee.extension';

@Component({
  selector: 'app-verify-employee-sim',
  templateUrl: './verify-employee-sim.component.html',
  styleUrls: ['./verify-employee-sim.component.scss'],
})
export class VerifyEmployeeSimComponent {
  currentStep: number = 0;
  steps: { title: string; subtitle: string }[] = [];
  public validatePhoneData!: any;
  public uploadedDocuments!: any;
  @Input() employeeData: any;
  @Input() companyId!: string;
  @Output() goBackToTable = new EventEmitter<void>();

  constructor(private router: Router) {
  }

  ngOnInit(): void {

    this.initializeSteps();
    if (this.employeeData.state === EmployeeState.PHONE_VERIFIED || EmployeeState.REJECTED) {
      this.currentStep = 1; 
    }
  }

  initializeSteps(): void {
    this.steps = [
      { title: 'Paso 1', subtitle: 'Validar Teléfono' },
      { title: 'Paso 2', subtitle: 'Subir Documentos' }
    ];
  }

  nextStep(): void {
    if (this.currentStep < this.steps.length - 1) {
      this.currentStep++;
    }
  }

  prevStep(): void {
    if (this.currentStep > 0) {
      this.currentStep--;
    }
  }

  handleValidatePhoneData(data: any): void {
    this.validatePhoneData = data;
    this.nextStep();
  }

  handleUploadedDocuments(data: any): void {
    this.uploadedDocuments = data;
    this.nextStep();
  }

  handleEmployeeData(data: any): void {
    this.employeeData = data;
    this.nextStep();
  }

  goToTable() {
    this.goBackToTable.emit()
  }
}
