<div class="all-searchs"[ngClass]="{ 'receipts' : isReceipts, 'cards': isCards}">
  <div class="desplegable">
  <mat-accordion>
    <mat-expansion-panel expanded="false" class="mat-elevation-z0">
      <mat-expansion-panel-header>
        <mat-panel-title> 
          <mat-icon>search</mat-icon>
        <p>Buscar movimiento</p> </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="filterForm">
        <div class="filter-box">
          <div class="period_container">
            <span>Por periodo:</span>
            <div class="inputs">
              <mat-form-field appearance="outline" color="primary">
                <mat-label>Desde</mat-label>
                <input formControlName="startDate" matInput [matDatepicker]="pickerStart"/>
                <mat-hint>dd/MM/AAAA</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="pickerStart"></mat-datepicker-toggle>
                <mat-datepicker #pickerStart></mat-datepicker>
              </mat-form-field>
              <mat-form-field appearance="outline" color="primary">
                <mat-label>Hasta</mat-label>
                <input formControlName="endDate" matInput [matDatepicker]="pickerEnd" />
                <mat-hint>dd/MM/AAAA</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="pickerEnd"></mat-datepicker-toggle>
                <mat-datepicker #pickerEnd></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
          <div class="amount_container">
            <span>Por importe:</span>
            <div class="inputs">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Mínimo:</mat-label>
                <input formControlName="minAmount" matInput placeholder="0.00€" (keypress)="preventLetters($event)" value="{{ formattedMinAmount }}" (change)="formatMinAmount()"/>
                <mat-icon matSuffix>euro</mat-icon>
              </mat-form-field>
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Máximo:</mat-label>
                <input formControlName="maxAmount" matInput placeholder="0.00€" value="{{ formattedMaxAmount }}"  (change)="formatMaxAmount()"
                  (blur)="formatMaxAmount()" />
                  <mat-icon matSuffix>euro</mat-icon>
              </mat-form-field>
            </div>
            <div class="button-filter">
              <button class="gold-button" (click)="onSubmit()">
                Buscar
              </button>
            </div>
          </div>
        </div>
      </form>
    </mat-expansion-panel>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    </mat-expansion-panel>
  </mat-accordion>
</div>
</div>
