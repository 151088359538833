export enum EmployeeState {
  REGISTERED = 'REGISTERED',
  PHONE_VERIFIED = 'PHONE_VERIFIED',
  DOCUMENT_VERIFIED = 'DOCUMENT_VERIFIED',
  PENDING_VERIFICATION = 'PENDING_VERIFICATION',
  REJECTED = 'REJECTED',
}

export const stateEmployee = (state: string) => {
  switch (state) {
    case EmployeeState.REGISTERED:
      return 'Registrado';
    case EmployeeState.DOCUMENT_VERIFIED:
      return 'Verificado';
    case EmployeeState.PHONE_VERIFIED:
      return 'Tlf. Verificado';
    case EmployeeState.PENDING_VERIFICATION:
      return 'Pte. Verificación';
    case EmployeeState.REJECTED:
      return 'Rechazado';
    default:
      return '';
  }
};
export function getStateClass(state: string): string {
  switch (state) {
    case EmployeeState.DOCUMENT_VERIFIED:
      return 'verified';
    case EmployeeState.PHONE_VERIFIED:
      return 'phone-verified';
    case EmployeeState.REGISTERED:
      return 'registered';
    case EmployeeState.PENDING_VERIFICATION:
      return 'phone-verified';
    case EmployeeState.REJECTED:
      return 'rejected';
    default:
      return '';
  }
}
