
<div class="simulator-container">
  <button class="gold-button" (click)="goToTable()"><img
    src="../../../../../../../assets/img/svg/Arrow-Left.svg" alt="">Volver a empleados</button>
  <div class="stepper">
    <div class="stepper-header">
      <div class="step" *ngFor="let step of steps; let i = index">
        <div class="step-circle-wrapper">
          <div
            class="step-circle"
            [class.completed]="i < currentStep"
            [class.active]="i === currentStep"
          >
            <ng-container *ngIf="i < currentStep">
              <img src="../../../../../../assets/img/svg/Check.svg" alt="✔️" />
            </ng-container>
            <ng-container *ngIf="i >= currentStep">{{ i + 1 }}</ng-container>
          </div>
          <div
            class="step-line"
            *ngIf="i < steps.length - 1"
            [class.line-active]="i < currentStep"
          ></div>
        </div>
      </div>
    </div>

    <div class="step-content">
      <ng-container *ngIf="currentStep === 0">
        <app-validate-phone
          [employeeData]="employeeData"
          (next)="handleValidatePhoneData($event)"
        ></app-validate-phone>
      </ng-container>

      <ng-container *ngIf="currentStep === 1">
        <app-upload-documents
        [companyId]="companyId"
          [employeeData]="employeeData"
          (next)="handleUploadedDocuments($event)"
          (prev)="prevStep()"
        ></app-upload-documents>
      </ng-container>
    </div>
  </div>
</div>
