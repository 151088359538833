<mat-sidenav-container class="example-container" [hasBackdrop]="false">
  <mat-sidenav #sidenav [mode]="mode" [opened]="isVisible">
    <div class="action_btn">
      <button mat-icon-button (click)="hideNav()">
        <mat-icon>menu</mat-icon>
      </button>
    </div>
    <mat-list>
      @for(item of linkList; track $index){
      <div class="dropdown">
        <div class="list_icons">
          @if(!item.deploy){
          <div
            class="list_btn"
            routerLink="{{ item.url }}"
            routerLinkActive="activeRoute"
            (click)="navigateAndClose()"
          >
            @if(item.title === 'Denarius Finance'){
            <img src="assets/logos/favicon.svg" alt="icono-denarius" />
            }@else{
            <mat-icon>{{ item.matIcon }}</mat-icon>
            }
            <p>{{ item.title }}</p>
          </div>
          }@else if(item.deploy){
          <div class="list_btn" (click)="toggleConfig(item)">
            <mat-icon>{{ item.matIcon }}</mat-icon>
            <p>{{ item.title }}</p>
          </div>
          <div class="expand-collapse-icon" (click)="toggleConfig(item)">
            <mat-icon class="arrow">
              {{ item.linkDeployed ? "expand_more" : "chevron_right" }}
            </mat-icon>
          </div>
          }
        </div>
        @if(item.linkDeployed){
        <div
          class="group_segment"
          [@expandOnEnter]
          [@collapseOnLeave]
          mat-ripple
        >
          @for(subtitle of item.subtitles; track $index){
          <span
            class="button_segment"
            [routerLink]="subtitle.url"
            routerLinkActive="activeRoute"
            (click)="navigateAndClose()"
          >
            <mat-icon class="arrow-rigth">arrow_right</mat-icon>
            <p style="margin: 0">{{ subtitle.title }}</p>
          </span>
          }
        </div>
        }
      </div>
      }
    </mat-list>
  </mat-sidenav>
  <mat-sidenav-content>
    @if(!isVisible){
    <button mat-icon-button (click)="showNav()">
      <mat-icon>menu</mat-icon>
    </button>
    }
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
