<div class="card-container">
  <div *ngIf="showAlert" class="alert-container">
    <app-alerts
      [alertMessage]="alertMessage"
      [alertStatus]="alertStatus"
    ></app-alerts>
  </div>
  <div class="card-content" *ngIf="isDataLoaded">
    <div class="back">
      <button class="gold-button" (click)="backToList()">< Volver</button>
    </div>
    <section class="card-info">
      <div class="number-card">
        <h1 class="bolder">
          Tarjeta {{ dataCard.maskedPAN }}
          {{ dataCard.alias ? "/ " + dataCard.alias : "/ " + "Sin alias" }}
        </h1>
        <div class="container-line">
          @if(this.dataCard.status !== CARD_STATUS.ACTIVATED) {
             <button class="gold-button" (click)="sendActivationSms()">
            <p>Activar (SMS)</p>
          </button>
          }
         
          <button
            *ngIf="!hiddenCancel"
            class="gold-button"
            (click)="openToCancel()"
          >
            Cancelar
        </button>
        </div>
      </div>
      <div class="status">
        <h3>{{statusCard(dataCard.status)}}</h3>
      </div>
    </section>
    <section class="width-table">
      <app-info-table [infoTable]="infoTable"></app-info-table>
    </section>
    <div class="container-btn">
      <div class="container-btn-line">
        <button class="gold-button" (click)="showPIN()">
          <p>Ver PIN</p>
         </button> 
        <button class="gold-button" (click)="onViewContract()">
          <p>Ver contrato tarjeta</p> 
        </button>
      </div>
    </div>
    <div>
      <ng-container 
      *ngIf="isPdfVisible && filePreview">
      <pdf-viewer 
        [src]="filePreview" 
        [original-size]="false" 
        [zoom-scale]="'page-width'"
        style="width: 93%; height: 300px; border-radius: 6px; overflow: hidden; margin-left: 24px; margin-top: 12px;">
      </pdf-viewer>
    </ng-container>
     </div>
    <section class="width-table">
      <h2 class="margin">Límites de la operación</h2>
      <app-info-table [infoTable]="infoLimits"></app-info-table>
    </section>
  </div>
  <div *ngIf="!dataCard?.name" class="skeleton-container">
    <ngx-skeleton-loader
      appearance="line"
      [count]="count"
      [theme]="{height: '40px'}"
    ></ngx-skeleton-loader>
  </div>
</div>
