<div class="phone-validation-container">
  <h1>Validación de Teléfono</h1>

  @if(!smsSent) {
     <form [formGroup]="phoneForm" (ngSubmit)="requestSmsVerification()">
    <label for="phone">Número de Teléfono</label>
    <input type="text" id="phone" formControlName="phoneNumber" placeholder="Ingrese su número de teléfono" />

    <button class="gold-button" type="submit" [disabled]="phoneForm.invalid || requestingSms">
      {{ requestingSms ? 'Enviando...' : 'Solicitar Código' }}
    </button>
  </form>
  } @else {
    <form [formGroup]="otpForm" (ngSubmit)="verifyPhone()">
      <label for="otp">Código OTP:</label>
      <input type="text" id="otp" formControlName="otpCode" placeholder="Ingrese el código recibido" />

      <button class="gold-button" type="submit" [disabled]="otpForm.invalid || verifyingOtp">
        {{ verifyingOtp ? 'Verificando...' : 'Verificar Código' }}
      </button>
    </form>
  }
  <p *ngIf="verificationStatus" class="verification-status" [ngClass]="{'error': !uploadStatusSuccess, 'success': uploadStatusSuccess}">{{ verificationStatus }}</p>
</div>
