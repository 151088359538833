import { finalize } from 'rxjs';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CompanyService } from 'src/app/core/services/userArea/company.service';
import { BusinessService } from 'src/app/core/services/user/business.service';

@Component({
  selector: 'app-upload-documents',
  templateUrl: './upload-documents.component.html',
  styleUrls: ['./upload-documents.component.scss']
})
export class UploadDocumentsComponent implements OnInit {
  @Input() companyId!: string;
  @Input() employeeData!: any;
  @Output() documentUploaded = new EventEmitter<void>();
  @Output() next = new EventEmitter<void>();

  uploadForm: FormGroup;
  frontFile: File | null = null;
  backFile: File | null = null;
  frontPreviewUrl: string | null = null;
  backPreviewUrl: string | null = null;
  frontFileName: string | null = null;
  backFileName: string | null = null;
  loading: boolean = false;
  verificationStatus: string = '';
  uploadStatusSuccess!: boolean;
  employeeId!: string | null;
  constructor(private fb: FormBuilder, private companyService: BusinessService, private router: Router) {
    this.uploadForm = this.fb.group({
      frontFile: [null, Validators.required],
      backFile: [null, Validators.required],
    });
  }

  ngOnInit(): void {
    console.log(this.companyId)
    this.getEmployeeId();
  }

  getEmployeeId(): void {
    if (!this.companyId || !this.employeeData?.identifier) {
      return;
    }

    this.companyService.getEmployees(this.companyId).subscribe({
      next: (employees) => {
        const foundEmployee = employees.find((emp: any) => emp.identifier === this.employeeData.identifier);
        if (foundEmployee) {
          this.employeeId = foundEmployee.id;
        } else {
        }
      },
      error: (error) => {
      }
    });
  }

  onFileChange(event: Event, type: 'front' | 'back'): void {
    const target = event.target as HTMLInputElement;
    const file = target.files?.[0];

    if (!file) return;

    const allowedTypes = ['image/png', 'image/jpeg'];
    if (!allowedTypes.includes(file.type)) {
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      if (type === 'front') {
        this.frontFile = file;
        this.frontPreviewUrl = reader.result as string;
        this.frontFileName = file.name;
        this.uploadForm.patchValue({ frontFile: file });
      } else {
        this.backFile = file;
        this.backPreviewUrl = reader.result as string;
        this.backFileName = file.name;
        this.uploadForm.patchValue({ backFile: file });
      }

      this.uploadForm.markAsTouched();
      this.uploadForm.updateValueAndValidity();
    };

    reader.onerror = () => {
      console.error(`Error al leer el archivo ${file.name}`);
    };

    reader.readAsDataURL(file);
  }

  async onSubmit(): Promise<void> {
    if (!this.companyId || !this.employeeId) {
      return;
    }

    if (!this.frontFile || !this.backFile) {
      return;
    }

    this.loading = true;

    const formData = new FormData();
    formData.append('data', JSON.stringify({ type: 'IDE' }));
    formData.append('files', this.frontFile);
    formData.append('files', this.backFile);

    this.companyService.uploadIdentifier(this.companyId, this.employeeId, formData)
    .subscribe({
      next: (response) => {
        this.next.emit();
        this.loading = false;
        this.verificationStatus =  'Documentos subidos correctamente'
        this.uploadStatusSuccess = true
      },
      error: (error) => {
        this.loading = false;
        this.verificationStatus = 'Error al subir los documentos. Inténtelo de nuevo'
        this.uploadStatusSuccess = false
      },
    });
  }

  resetForm(): void {
    this.uploadForm.reset();
    this.frontFile = null;
    this.backFile = null;
    this.frontPreviewUrl = null;
    this.backPreviewUrl = null;
    this.frontFileName = null;
    this.backFileName = null;
    this.verificationStatus = '';

    this.router.navigate(['/empresas/empleados']);
  }
}
