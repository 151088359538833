import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BusinessService } from 'src/app/core/services/user/business.service';
import { CompanyService } from 'src/app/core/services/userArea/company.service';

@Component({
  selector: 'app-validate-phone',
  templateUrl: './validate-phone.component.html',
  styleUrls: ['./validate-phone.component.scss'],
})
export class ValidatePhoneComponent {
  @Input() employeeData!: any;
  @Output() next = new EventEmitter<void>();

  phoneForm: FormGroup;
  otpForm: FormGroup;
  verificationStatus: string = '';
  requestingSms: boolean = false;
  verifyingOtp: boolean = false;
  smsSent: boolean = false;
  uploadStatusSuccess!: boolean;


  constructor(private fb: FormBuilder, private companyService: BusinessService) {
    this.phoneForm = this.fb.group({
      phoneNumber: ['', [Validators.required, Validators.pattern(/^[0-9]{9,15}$/)]],
    });

    this.otpForm = this.fb.group({
      otpCode: ['', [Validators.required, Validators.pattern(/^[0-9]{4,6}$/)]],
    });
  }

  ngOnInit(): void {
    if (this.employeeData?.phone) {
      this.phoneForm.patchValue({ phoneNumber: this.employeeData.phone });
    }
  }

  requestSmsVerification(): void {
    if (!this.employeeData?.companyId || !this.employeeData?.id) {
      return;
    }

    if (this.phoneForm.invalid) {
      this.verificationStatus = 'Número de teléfono no válido';
      return;
    }

    this.requestingSms = true;
    const body = { phone: this.phoneForm.value.phoneNumber };

    this.companyService.requestSmsVerification(this.employeeData.companyId, this.employeeData.id, body).subscribe({
      next: () => {
        this.smsSent = true;
        this.verificationStatus = '📨 Código enviado al número ingresado';
        this.uploadStatusSuccess = true;
      },
      error: (error) => {
        this.verificationStatus = 'Error al enviar SMS. Inténtelo de nuevo.';
        this.requestingSms = false;
        this.uploadStatusSuccess = false;

      },
      complete: () => {
        this.requestingSms = false;
      }
    });
  }

  verifyPhone(): void {
    if (!this.employeeData?.companyId || !this.employeeData?.id) {
      return;
    }

    if (this.otpForm.invalid) {
      this.verificationStatus = 'Código OTP inválido';
      return;
    }

    this.verifyingOtp = true;
    const otp = this.otpForm.value.otpCode;

    this.companyService.verifyPhone(this.employeeData.companyId, this.employeeData.id, otp).subscribe({
      next: () => {
        this.verificationStatus = 'Teléfono verificado correctamente';
        this.next.emit();
      },
      error: (error) => {
        this.verificationStatus = 'Error en la verificación. Inténtelo de nuevo.';
        this.verifyingOtp = false;
      },
      complete: () => {
        this.verifyingOtp = false;
      }
    });
  }
}
