import { PrefixMockService } from '../../../../core/services/selects/prefix.mock-service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { allRegex } from 'src/app/core/extensions/regex';
import { ActivityList, Country, CountrySelect, Prefix } from 'src/app/core/models/selects/selects';
import { SelectFormMockService } from 'src/app/core/services/selects/countrySelect.mock-service';
import { DatePipe } from '@angular/common';
import { ActivityListMockService } from 'src/app/core/services/selects/activityLists';
import { clearValidator, setValidator } from 'src/app/core/extensions/formFunctions.extensions';
import { SelectListMockService } from 'src/app/core/services/selects/selectsList';

@Component({
  selector: 'app-personal-data',
  templateUrl: './personal-data.component.html',
  styleUrls: ['./personal-data.component.scss'],
})
export class PersonalDataComponent implements OnInit {
  public personalData!: FormGroup
  public country!: Country[];
  public prefix!: Prefix[];
  public pipe!: any;
  public todayWithPipe!: any;
  public cnoList!: ActivityList[];
  public incomeSelect!: Country[];
  _isBusiness?: boolean;
  _isIndividual?: boolean;
  _isRepresentative?:boolean;
  _isPartner!: boolean;
  public hasPepRole: boolean = false
  public pepRoleList?: any[];
  public autoFill!: boolean;
  /*  public minDate!: Date;
   public maxDate!: Date */
  public startDate!: Date;
  public formControlDNI!: string;
  public activityControl: FormControl = new FormControl('', [Validators.required]);

  public filteredOptions: any[] = [];

  @Input() dataLength: number = 0;
  @Input() isAdministrator?: boolean;
  @Input() representativeData:any;
  @Input() isEmployee?: boolean;
  @Input() set isRepresentative(value: boolean){
    if (!value) {
      return
    } else{
      this._isRepresentative = true;

      this.activityList.pepRole().subscribe(res => {
        this.pepRoleList = res
      })
      this.personalData.valueChanges.subscribe(res => {
        this.personalDataEvent.emit(this.personalData);
      })

    }
  }
  @Input() set isPartner (value: boolean) {
    if (!value) {
      return
    } else{
      this._isPartner = true
      this.personalData.addControl('equity', new FormControl('', Validators.required))
    }
  };
  @Input() set isIndividual(value: boolean) {
    if (!value) {
      return
    } else {
      this._isIndividual = true;
      this.personalData.addControl('activity', new FormControl('', Validators.required))
      this.personalData.addControl('income', new FormControl('', Validators.required))
      this.personalData.addControl('password', new FormControl('', [Validators.required, Validators.pattern(allRegex.regexPin)]))
      this.personalData.addControl('rePassword', new FormControl('', Validators.required))

      this.personalData.valueChanges.subscribe(res => {
        const pin = this.personalData.get('password')?.value
        const repeatPin = this.personalData.get('rePassword')?.value
        if (pin !== repeatPin) {
          this.personalData.get('rePassword')?.setErrors({ rePasswordError: 'cambia el pin' })
        }
        this.personalDataEvent.emit(this.personalData);
      })

    }
  }
  @Output() personalDataEvent = new EventEmitter<FormGroup>();
  @Output() userBusiness = new EventEmitter<FormGroup>();
  @Output() cancelRegistrationEvent = new EventEmitter<boolean>();
  @ViewChild('activityInput') activityInput!: ElementRef<HTMLInputElement>;
  
  constructor(
    private formBuilder: FormBuilder,
    private selectDataService: SelectFormMockService,
    private prefixMockService: PrefixMockService,
    private activityList: ActivityListMockService,
    private selectList: SelectListMockService
  ) {

    this.personalData = this.formBuilder.group({
      name: ['', [Validators.required]],
      surname1: ['', [Validators.required]],
      surname2: [''],
      birthdate: ['', [Validators.required]],
      documentType: ['DNI', [Validators.required]],
      gender: ['', [Validators.required]],
      identifier: ['', [Validators.required, Validators.pattern(allRegex.regexDNI)]],
     /*  nie:[''], */
      expeditionCountry: ['ES', [Validators.required]],
      residenceCountry: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern(allRegex.regexPhoneAndEmail)]],
      prefix: ['', [Validators.required]],
      phone: ['', [Validators.required, Validators.pattern(allRegex.regexPhoneAndEmail)]],
      nationality: ['', [Validators.required]],
      activity: ['', Validators.required],
    });
  }


  ngOnInit() {
    this.formControlDNI = 'identifier'
    this.datePickerStart()
    this.selectDataService.selectList().subscribe(
      data => {
        this.country = data.country;
      }
    )

    this.prefixMockService.preList().subscribe(
      data => {
        this.prefix = data.prefix;
      }
    )
    this.activityList.cnoSelectList().subscribe(
      res => {
        this.cnoList = res
        this.cnoList.sort((a, b) => a.name.localeCompare(b.name));
      }
    )

    this.selectList.incomeList().subscribe(
      res => {
        this.incomeSelect = res
      }
    )

    this.personalData.valueChanges.subscribe(res => {
      const pin = this.personalData.get('password')?.value
      const repeatPin = this.personalData.get('rePassword')?.value
      if (pin !== repeatPin) {
        this.personalData.get('rePassword')?.setErrors({rePasswordError: 'cambia el pin'})
      }
      this.personalDataEvent.emit(this.personalData);
        
    })

  } 

  focusActivity(){
    if(this.activityInput.nativeElement.value){
      this.filteredOptions = this.cnoList
    }else{
      this.filterActivity()
    }
  }
  
  
  filterActivity(): void {
    let filterValue: string;
    filterValue = this.activityInput.nativeElement.value.toLowerCase();
    if (!filterValue) {
      this.personalData.get('activity')?.reset()
    }
    this.filteredOptions = this.cnoList.filter((option: any) =>
      option.name.toLowerCase().includes(filterValue) ||
      option.code.toLowerCase().includes(filterValue)
    );
    if(this.filteredOptions.length == 0) {
      this.personalData.get('activity')?.reset()

    }
  }

  setActivity(event: any) {
    if (event && event.code) {
      this.personalData.get('activity')?.setValue(event.code);
    }
  }

  displayFn(activity: any): string {
    return activity && activity.name ? activity.name : '';
  }


  ngOnChanges() {
    if(this.isEmployee) {
      this.personalData.addControl('role', new FormControl('', Validators.required))
    }
  }

  idDocumentSelect(){
    if(this.personalData.get('documentType')?.value === 'DNI'){
      this.formControlDNI = 'identifier';
      this.personalData.addControl('identifier', new FormControl('', [Validators.required, Validators.pattern(allRegex.regexDNI)]))
      this.personalData.removeControl('nie');
    }else if (this.personalData.get('documentType')?.value === 'NIE'){
      this.formControlDNI = 'nie';
      this.personalData.addControl('nie', new FormControl('', [Validators.required, Validators.pattern(allRegex.regexNIE)]))
      this.personalData.removeControl('identifier');    
    }
  }
  datePickerStart(){
    const today = new Date(Date.now())
    this.startDate = new Date( today.getFullYear() - 18, today.getMonth(), today.getDate())
  }

/*   formattedDate(){
    const birthdateValue = this.personalData.get('birthdate')?.value;
    this.pipe = new DatePipe('en-US');
    this.todayWithPipe = this.pipe.transform(birthdateValue, 'yyyy-MM-dd');
    this.personalData.get('birthdate')?.setValue(this.todayWithPipe);
  } */

  togglePepRole(){
    if(this.hasPepRole){
      this.personalData.addControl('pepRole', new FormControl('', Validators.required))
      this.personalData.addControl('pepCountry', new FormControl('', Validators.required))
    }else{
      this.personalData.removeControl('pepRole')
      this.personalData.removeControl('pepCountry')
    }
  }
  autoFillRepresentative() {

    if (this.autoFill) {
      this.personalData.patchValue({
        documentType: this.representativeData.documentType,
      })
      this.idDocumentSelect()
      const patchedFields = Object.keys(this.representativeData);
      patchedFields.forEach((field) => {
        const formControl = this.personalData.get(field);
        if (formControl) {
          formControl.disable();
        }
      });

      this.patchValues()
    } else {
      this.personalData.reset();
      const patchedFields = Object.keys(this.representativeData);
      patchedFields.forEach((field) => {
        const formControl = this.personalData.get(field);
        if (formControl) {
          formControl.enable();
        }
      });
    }
  }

  patchValues() {
    this.personalData.patchValue({
      name: this.representativeData.name,
      surname1: this.representativeData.surname1,
      surname2: this.representativeData.surname2,
      birthdate: this.representativeData.birthdate,
      gender: this.representativeData.gender,
      identifier: this.representativeData.identifier,
      nie: this.representativeData.nie,
      expeditionCountry: this.representativeData.expeditionCountry,
      residenceCountry: this.representativeData.residenceCountry,
      email: this.representativeData.email,
      prefix: this.representativeData.prefix,
      phone: this.representativeData.phone,
      nationality: this.representativeData.nationality,
    })
  }

  resetForm () {
    this.personalData.reset()
  }

  onSubmit() {
    if (this.autoFill == true) {
      const patchedFields = Object.keys(this.representativeData);
      patchedFields.forEach((field) => {
        const formControl = this.personalData.get(field);
        if (formControl) {
          formControl.enable();
        }
      });
    }
      this.userBusiness.emit(this.personalData);
      this.personalData.reset()
  }

  sendIfAdministrator(){
    if(this.isAdministrator){
      this.onSubmit()
    }
  }


  cancelRegistration(){
    this.cancelRegistrationEvent.emit(true)
  }
  setError(control: string){
    this.personalData.get(control)?.setErrors({exists: true})
  }

}
