<div class="page_container" *ngIf="_userData && userAccounts && userAccounts.length >= 1">
  <div class="accounts_flex">
    <div class="selector_container">
      <app-selector-accounts
        [userAccounts]="userAccounts"
        [userName]="userName"
        [userId]="_userData.id"
        (selectedAccount)="receiveSelectedAccount($event)"
      ></app-selector-accounts>
    </div>
    
    <div class="accounts_filter">
      <app-filter
        class="app-filter"
        (filtersEvent)="receiveFilters($event)"
      ></app-filter>
    </div>

<div class="create-account-button">
  <button class="gold-button" (click)="openCreateAccountModal()">+ Crear nueva cuenta</button>
</div>
  </div>

  <div class="accounts_month-filter">
    <div class="icons-list">
      <img
        class="svg_icon"
        (click)="exportToExcel()"
        src="../../../../../assets/img/svg/excel-full.svg"
        alt="excel_icon"
      />
      <button mat-button (click)="resetFilters()">Borrar filtros</button>
    </div>
  </div>

  <div class="table_container">
    <app-accounts-table
      [selectedAccountId]="selectedAccountId"
      [filterParams]="filterParams"
      (sendMovementsDownload)="receiveMovements($event)"
    ></app-accounts-table>
  </div>
</div>

<p class="no-p" *ngIf="userAccounts && userAccounts.length == 0">No hay cuentas</p>
