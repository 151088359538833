<div mat-dialog-content class="modal-container">
  <p>{{message}}</p>
  <mat-form-field class="input" *ngIf="hasInput" appearance="outline">
    <input matInput [(ngModel)]="inputValue">
  </mat-form-field>
  @if(errorMessage){
  <mat-error>{{errorMessage}}</mat-error>
  }
  <div mat-dialog-actions class="modal-actions">
    @if (!isLoading) {
    <button class="modal-button cancel" mat-dialog-close>{{cancel}}</button>
    <button class="modal-button" [disabled]="hasInput && !inputValue" *ngIf="actionFunction"
      (click)="launchFunction()">{{confirm}}</button>
    <button class="modal-button" [mat-dialog-close]="true" *ngIf="!actionFunction">{{confirm}}</button>
    }
    @else {
    <div class="modal-spinner">
      <mat-spinner class="spinner"></mat-spinner>
    </div>
    }

  </div>
</div>