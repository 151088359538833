import { HttpClient, HttpParams} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { BusinessData, ParticularData } from "../../models/financing/userFinancingData";


@Injectable({
  providedIn: 'root',
})
export class FinancingService {
  constructor(private http: HttpClient) { }

  public getCustomers(): Observable<any>{
    return this.http.get<any>(`${environment.financingUrl}/customers`)
  }


  public getDetailOperation(identifier: string): Observable<any>{
    return this.http.get<any>(`${environment.financingUrl}/customer/by-identifier/${identifier}`)
  }

  public getContract(idOperation: string): Observable<Blob> {
    return this.http.get<Blob>(`${environment.financingUrl}/financing/${idOperation}/download-contract`, {
      responseType: 'blob' as 'json'
    });
  }

  public changeStateOperation(idOperation: string, state: string): Observable<any> {
    const body = {
        financingId: idOperation,
        state: state
    };

    return this.http.post<any>(`${environment.financingUrl}/financing/${idOperation}/change-state`, body);
}

  public findByFilter(searchText: string, size: number, page: number): Observable<any> {
    let params = new HttpParams()
      .set('searchtext', searchText)
      .set('size', size)
      .set('page', page);

    return this.http.get<any>(`${environment.financingUrl}/financings`, { params });
  }

  public calculateFinancing(body: any): Observable<any> {
    return this.http.post<any>(`${environment.financingUrl}/financing/calculate`, body);
  }

  public getByIdentifier(identifier: string): Observable<any[]> {
    return this.http.get<any[]>(`${environment.financingUrl}/customer/by-identifier/${identifier}`);
  }
  public requestContract(customerId: string): Observable<any> {
    return this.http.post<any>(`${environment.financingUrl}/financing/${customerId}/request-contract`, null , { responseType: 'text' as 'json' })
  }
  public updateFinancing(idOperation: string, updatedFields: any): Observable<any> {
    return this.http.put<any>(`${environment.financingUrl}/financing/${idOperation}`, updatedFields);
  }

  public createFinancing(data: any): Observable<string> {
    const url = `${environment.financingUrl}/financing`;
    return this.http.post<string>(url, data, { responseType: 'text' as 'json' });
  }
  public getFinancing(financingId: string): Observable<any[]> {
    return this.http.get<any[]>(`${environment.financingUrl}/financing/${financingId}`);
  }

  public uploadDocument(idFinancing: string, file: File, description: string): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('description', description);
  
    return this.http.post<any>(`${environment.financingUrl}/financing/${idFinancing}/documents/upload`, formData);
  }
  

  public getFinancingDocuments(idFinancing: string): Observable<any> {
    return this.http.get<any>(`${environment.financingUrl}/financing/${idFinancing}/documents`);
  }

  public downloadDocument(idFinancing: string, idDocument: string): Observable<Blob> {
    return this.http.get<Blob>(`${environment.financingUrl}/financing/${idFinancing}/documents/${idDocument}/download`, {
      responseType: 'blob' as 'json'
    });
  }

  public deleteDocument(idFinancing: string, idDocument: string): Observable<any> {
    return this.http.delete<any>(`${environment.financingUrl}/financing/${idFinancing}/documents/${idDocument}`);
  }  
  
  public uploadSigningDocuments(idFinancing: string, file: File, description: string): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('description', description);

    return this.http.post<any>(`${environment.financingUrl}/financing/${idFinancing}/documents-sign/upload`, formData);
  }

  public addSignerDocument(idFinancing: string, signerData: any): Observable<any> {
    return this.http.post<any>(`${environment.financingUrl}/financing/${idFinancing}/documents-sign/signer`, signerData, { responseType: 'json' });
  }

  public listFinancingSigners(idFinancing: string): Observable<any[]> {
    return this.http.get<any[]>(`${environment.financingUrl}/financing/${idFinancing}/documents-sign/signers`);
  }

  public editSignerDocument(idFinancing: string, idSigner: string, updatedSignerData: any): Observable<any> {
    return this.http.post<any>(
        `${environment.financingUrl}/financing/${idFinancing}/documents-sign/signer/${idSigner}/edit`,
        updatedSignerData,
        { responseType: 'json' }
    );
  }

  public deleteSignerDocument(idFinancing: string, idSigner: string): Observable<any> {
    return this.http.delete<any>(`${environment.financingUrl}/financing/${idFinancing}/documents-sign/signer/${idSigner}/delete`);
}

public deleteSigningDocument(idFinancing: string, idDocument: string): Observable<any> {
    return this.http.delete<any>(`${environment.financingUrl}/financing/${idFinancing}/documents-sign/document/${idDocument}/delete`);
}

public editCustomer(type: 'company' | 'autonomous', idCustomer: string, updatedCustomerData: any): Observable<any> {
  return this.http.post<any>(
      `${environment.financingUrl}/customer/${type}/edit/${idCustomer}`,
      updatedCustomerData,
      { responseType: 'json' }
  );
}

public getSignedDocuments(idFinancing: string): Observable<any[]> {
  return this.http.get<any[]>(
    `${environment.financingUrl}/financing/${idFinancing}/documents-sign/signings`
  );
}

public downloadSignedDocument(idFinancing: string, idDocument: string): Observable<Blob> {
  return this.http.get<Blob>(`${environment.financingUrl}/financing/${idFinancing}/documents-sign/${idDocument}/download`, {
      responseType: 'blob' as 'json'
  });
}

public sendSignersToSign(financingId: string,signingDocumentsId: string,signers: string[]): Observable<any> {
  const body = { signers };
  return this.http.post<any>(
    `${environment.financingUrl}/financing/${financingId}/documents-sign/${signingDocumentsId}/signers-send-to-sign`,
    body
  );
}

public getSignerSigningStatus( signingDocumentsId: string, idSigner: string ): Observable<any> {
  return this.http.get<any>(
    `${environment.financingUrl}/financing/documents-sign/${signingDocumentsId}/signer/${idSigner}/signing-status`
  );
}

public updateInstallmentDate(financingId: string, date: string): Observable<any> {
  const body = { date };
  return this.http.post<any>(
    `${environment.financingUrl}/financing/${financingId}/update-installment-date`,
    body,
    { responseType: 'json' }
  );
}

public changeSignerSigningStatus( signingDocumentsId: string, idSigner: string, state: 'PENDING' | 'SEND' | 'SIGNED' | 'CANCELLED' ): Observable<any> {
  const body = { state };
  return this.http.post<any>(
    `${environment.financingUrl}/financing/documents-sign/${signingDocumentsId}/signer/${idSigner}/signing-change-status`,
    body
  );
}

public changeDocumentState(
  idFinancing: string,
  idDocument: string,
  state: 'PENDING' | 'SEND' | 'SIGNED' | 'CANCELLED'
): Observable<any> {
  const body = { state };

  return this.http.post<any>(
    `${environment.financingUrl}/financing/${idFinancing}/documents-sign/${idDocument}/change-state`,
    body,
    { responseType: 'json' }
  );

  
}

// Sales point services

public getSalesPoints(): Observable<any> {
  return this.http.get<any[]>(`${environment.financingUrl}/salespoints`);
}

createSalesPoint(salesPointData: any): Observable<string> {
  return this.http.post(`${environment.financingUrl}/salespoint`, salesPointData, {
    responseType: 'text'
  });
}


public updateFinancingSalesPoint(financingId: string, salesPointId: string): Observable<any> {
  const body = { salesPointId };
  return this.http.patch<any>(`${environment.financingUrl}/financing/${financingId}/sales-point`, body);
}

}




