import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Country, ZipCodeModel } from 'src/app/core/models/selects/selects';
import { BusinessProfile } from 'src/app/core/models/user/businessData';
import { AddressProfile, Profile } from 'src/app/core/models/user/userData';
import { SelectFormMockService } from 'src/app/core/services/selects/countrySelect.mock-service';

@Component({
  selector: 'app-card-address',
  templateUrl: './card-address.component.html',
  styleUrl: './card-address.component.scss'
})
export class CardAddressComponent {
  public country!: Country[]
  public zipCode!: ZipCodeModel[]
  public cardShippingForm!: FormGroup;
  public selectedAddress: string = 'habitual';
  public regularAddress! : any;

  @Input() userData!: any;
  @Input() userType!: string
  @Output() prev = new EventEmitter<void>();
  @Output() next = new EventEmitter<any>();  
  @Output() cardShippingData = new EventEmitter<AddressProfile>();
 

  constructor(private fb: FormBuilder, private selectDataService: SelectFormMockService) {
    this.cardShippingForm = this.fb.group({
      addressType: ['habitual', Validators.required],
      street: ['', Validators.required],
      number: ['', Validators.required],
      flat: [''],
      country: ['', Validators.required],
      city: ['', Validators.required],
      zip: ['', [Validators.required, Validators.pattern('[0-9]{5}')]]
    });
}

ngOnChanges() {
  if(this.userData) {
    this.regularAddress = this.userData.addresses[0];
    this.setRegularAddress()
  }
  this.selectDataService.selectList().subscribe(
    data => {
       this.country = data.country;
    }
  )
  
}

setRegularAddress() {
  this.cardShippingForm.patchValue(this.regularAddress);
  this.disableFields();  
}

setOtherAddress() {
  this.enableFields();
  this.cardShippingForm.patchValue({
    street: '',
    number: '',
    flat: '',
    country: '',
    city: '',
    zip: ''
  });
}

disableFields() {
  Object.keys(this.cardShippingForm.controls).forEach(control => {
    if (control !== 'addressType') {
      this.cardShippingForm.controls[control].disable();
    }
  });
}

enableFields() {
  Object.keys(this.cardShippingForm.controls).forEach(control => {
    if (control !== 'addressType') {
      this.cardShippingForm.controls[control].enable();
    }
  });
}

selectAddress(type: string) {
  this.selectedAddress = type;
  this.cardShippingForm.controls['addressType'].setValue(type);

  if (type === 'habitual') {
    this.setRegularAddress();
  } else {
    this.setOtherAddress();
  }
}

hasError(controlName: string, errorName: string) {
  return this.cardShippingForm.controls[controlName].hasError(errorName);
}

prevStep() {
  this.prev.emit();
}

nextStep() {
  if (this.selectedAddress === 'habitual') {
    this.cardShippingData.emit(this.regularAddress);
  } else if (this.cardShippingForm.valid) {

    const shippingData: AddressProfile = this.cardShippingForm.value;
    this.cardShippingData.emit(shippingData);
  }
  this.next.emit();
}
}