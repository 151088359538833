<div class="company-data-content">
  <button mat-icon-button (click)="closeModal()"><mat-icon>close</mat-icon></button>
  @if (installment) {
    <div class="tables">
      <div class="info-table">
        <app-info-table [infoTable]="infoDataTable"></app-info-table>
      </div>
      <div class="info-table">
        <app-info-table [infoTable]="infoInstallmentTable"></app-info-table>
      </div>
    </div>
    
  } @else {
    <p class="no-data">No hay datos disponibles para esta cuota.</p>
  }
</div>