import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { CoreService } from 'src/app/core/services/userArea/core.service';

@Component({
  selector: 'app-remittances-detail-component',
  templateUrl: './remittances-detail-component.component.html',
  styleUrls: ['./remittances-detail-component.component.scss']
})
export class RemittancesDetailComponentComponent implements OnInit {
  public remittanceId!: string;
  public remittanceData: any | null = null;
  public loader: boolean = true;
  public transfersLoaded: boolean = false; // Nueva variable para controlar la carga de la tabla
  public offset = 5;

  public displayedColumns: string[] = [
    'externalReference',
    'concept',
    'amount',
    'currency',
    'transferDate',
    'transferType',
    'remitterName',
    'beneficiaryName',
    'situation'
  ];

  public dataSource = new MatTableDataSource<any>([]);

  constructor(private route: ActivatedRoute, private coreService: CoreService, private router: Router) {}

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.remittanceId = params.get('id') || '';

      if (this.remittanceId) {
        this.getRemittanceDetail();
      }
    });
  }

  getRemittanceDetail() {
    this.loader = true;
    this.coreService.getUserDebits().subscribe({
      next: (data) => {
        this.remittanceData = data.data.find((item: any) => item.sefideRemittanceId === this.remittanceId) || null;
        if (this.remittanceData) {
          this.getRemittanceTransfers(this.remittanceData.id);
        } else {
          this.router.navigate(['/contratacion/adeudos'])
          console.error(`No se encontró la remesa con id: ${this.remittanceId}`);
          this.loader = false;
          this.transfersLoaded = false;
        }
      },
      error: (error) => {
        console.error('Error al obtener la remesa:', error);
        this.remittanceData = null;
        this.loader = false;
        this.transfersLoaded = false;
      }
    });
  }
  getRemittanceTransfers(remittanceId: string) {
    this.transfersLoaded = false;
    
    this.coreService.getRemittanceTransfers(remittanceId).subscribe({
      next: (data) => {
        this.dataSource.data = Array.isArray(data) ? data : (data.data || []);
        if (this.dataSource.data.length === 0) {
          console.warn('No se encontraron transferencias para esta remesa.');
        }
  
        this.dataSource._updateChangeSubscription();
        this.transfersLoaded = true;
        this.loader = false;
      },
      error: (error) => {
        console.error('Error al obtener las transferencias:', error);
        this.dataSource.data = [];
        this.transfersLoaded = false;
        this.loader = false;
      }
    });
  }
  
  formatDate(date: string | undefined): string {
    return date ? new Date(date).toLocaleDateString('es-ES') : '-';
  }
  translateState(state: string): string {
    switch (state?.toLowerCase()) {
      case 'ok': return 'Aprobado';
      case 'pending': return 'Pendiente';
      case 'cancelled': return 'Cancelado';
      default: return state;
    }
  }
  
  translateSituation(situation: string): string {
    switch (situation) {
      case 'E': return 'Ejecutado';
      case 'B': return 'Pagado';
      case 'P': return 'P. de proceso';
      case 'Y': return 'En proceso';
      case 'C': return 'Procesado parcialmente, pendiente de emisión por lote';
      case 'A': return 'Procesado parcialmente, pendiente de emisión individual';
      case 'W': return 'Procesado parcialmente, procesando emisión individual';
      case 'M': return 'Pendiente de solicitud de retrocesión';
      case 'T': return 'Pendiente de ser revertido';
      case 'K': return 'Pendiente de ser devuelto';
      case 'O': return 'Con problemas pendientes de resolver';
      case 'J': return 'Pendiente de justificación de fondos (para transferencias recibidas)';
      case 'S': return 'Pendiente de confirmación o SCA (para transferencias enviadas individualmente)';
      case 'Z': return 'Rechazado';
      case 'D': return 'Devuelto (emitido pero rebotado)';
      case 'L': return 'Devuelto por solicitud de retrocesión aceptada';
      case 'X': return 'Cancelado';
      case 'F': return 'Error (obsoleto)';
      default: return 'Desconocido';
    }
  }

  getStatusClass(situation: string): string {
    switch (situation.toLowerCase()) {
      case 'b':
        return 'status-aprobado';
      case 'f':
        return 'status-denegado';
      case 'p':
        return 'status-pendiente';
      case 'x':
        return 'status-cancelado';
      default:
        return '';
    }
  }
}
