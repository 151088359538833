import { catchError, map, of, tap } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { Component, Input ,HostListener, ViewChild, EventEmitter, Output} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AccountMovements } from 'src/app/core/models/accounts/account-movements';
import { UserService } from 'src/app/core/services/user/user.service';
​import { getMovementType } from 'src/app/core/extensions/movement.extension';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-accounts-table',
  templateUrl: './accounts-table.component.html',
  styleUrls: ['./accounts-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class AccountsTableComponent {
  public movementsData! : AccountMovements[];
  public displayedColumns: string[] = ['accountingDate', 'date', 'description', 'amount', 'balance'];
  public columnsToDisplayWithExpand = [...this.displayedColumns, 'expand'];
  public expandedElement!: any;
  public loader!: boolean;
  public accumulatedData: AccountMovements[] = [];
  ​
  // for Filters
  public _selectedAccountId!: string
  public _filterParams?: HttpParams;
  ​
  //For pagination
  public currentPage: number = 1
  public offset: number = 5
  public hideNextButton: boolean = false
  ​
  public allParams: HttpParams = new HttpParams()
  ​
  dataSource: any = [];

  public movementDetail!: any;
  public hideConcept!: boolean; 
  getMovementType = getMovementType;
  ​
  //Changes account ID
  @Input() set selectedAccountId(val:string){
    if( !val){
      return
    }else{
      this._selectedAccountId = val
      this.resetData()
      this.getMovements()
    }
  }
  ​
  //Filters by date and amount
  @Input() set filterParams(val:HttpParams | undefined){
    if (!val){
      return
    }else{
      this.filterParams
      this._filterParams = val
      this.allParams = this._filterParams
      console.log(this.allParams)
      this.resetData()
      this.getMovements()
    }
  }
  ​
  @Output() sendMovementsDownload: EventEmitter<any> = new EventEmitter<any>();
  ​
  ​
  constructor(private userService: UserService) {
  }
  ​
  ​
  ​
  ngOnInit(){
    this.loader = true;
    this.dataSource = new MatTableDataSource([]);
}
  
  getMovements(){
    this.loader = true
    if(this.currentPage == 1){
      this.allParams = this.allParams.set('page', this.currentPage).set('offset', this.offset)
    }
    this.userService.getMovements(this._selectedAccountId, this.allParams).subscribe((res) => {
      console.log('Movements data:', res); // Add this line
      this.movementsData = res;
      this.sendMovementsDownload.emit(this.movementsData)
      this.accumulatedData.push(... this.movementsData)
      console.log('DataSource:', this.dataSource); // Add this line
      this.dataSource = new MatTableDataSource(this.accumulatedData)
  ​
      if(this.movementsData.length < this.offset){
        this.hideNextButton = true;
      }else{
        this.hideNextButton = false;
      }
      this.loader = false;
  ​
    })
  }

  toggleExpandedRow(element: any): void {
    if (this.expandedElement === element) {
      this.expandedElement = null;
    } else {
      this.movementDetail = null
      this.expandedElement = element;
      this.getMovementDetail(this.expandedElement)
    }
  }

  getTransferDetail(movement: AccountMovements) {
    this.userService.getTransferDetail(localStorage.getItem('userId')!, this._selectedAccountId, movement.externalRef).pipe(
      tap((res) => {
        this.movementDetail = movement;
        this.movementDetail['concept'] = res.concepto
        this.movementDetail['beneficiary'] = res.nombreBeneficiario
      }),
      catchError((error) => {
        if (error.message === "client does not exits") {
          this.movementDetail = movement;
          this.hideConcept = true;
        }
        return of(null)
      })
    ).subscribe()
  }

  getMovementDetail(movement: AccountMovements) {
    if (movement.type === 'TRMO') {
      this.getTransferDetail(movement)
    } else {
      this.movementDetail = movement;
    }

  }
  ​
  resetData(){
    this.currentPage = 1;
    this.accumulatedData = []
  }
  ​
  nextPage(){
    this.currentPage = ++this.currentPage
    console.log(this.currentPage)
    this.allParams = this.allParams.set('page',this.currentPage).set('offset', this.offset)
    this.getMovements()
  }
}
